/* eslint-disable @typescript-eslint/ban-ts-comment */
import { apiFormData } from './index'

const getGeojson = async (request: {shapefile: File}) => {
  const formData = new FormData()
  Object.keys(request).forEach((key) => {
    // @ts-ignore
    if (request[key] !== 'undefined' && request[key] !== undefined) {
      // @ts-ignore
      formData.append(key, request[key])
    }
  })

  const response = await apiFormData.post('/geojson', formData)
  return response.data
}

export default getGeojson

/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react'
import { Input, Button, Select, Upload, Form, FormInstance } from 'antd'
import { PlusCircleOutlined, UploadOutlined, WarningOutlined } from '@ant-design/icons'
import {
  MapContainer,
  TileLayer,
  Marker,
  ZoomControl,
} from 'react-leaflet'
import { Map as MapLeaf } from 'leaflet'
import { Country, ProjectDeveloper, ProjectType } from '../../../types/project'
import { getProjectTypes } from '../../../services/project/projectTypeService'
import { getCountries } from '../../../services/project/countriesService'
import { getProjectDevelopers } from '../../../services/project/projectDeveloperService'
import { getUploadHeader, getUploadUrl } from '../../../services'
import { User } from '../../../types/user'
import { getWembers } from '../../../services/wembersService'
import { ProjectRequest } from '../../../types/service'
import getGeojson from '../../../services/geojsonService'
import { getParcelCenter, renderParcel } from '../../../tools/mapTools'
import CreateEditContentModal from '../modals/CreateEditContentModal'

const { Option } = Select

const ProjectDetails = ({ form }: {form: FormInstance<ProjectRequest | undefined>}) => {
  const [isOpen, setModal] = useState(false)
  const [typeModal, setType] = useState('')
  const [countries, setCountries] = useState<Country[]>([])
  const [types, setTypes] = useState<ProjectType[]>([])
  const [projectDevelopers, setProjectDevelopers] = useState<ProjectDeveloper[]>([])
  const [wembers, setWembers] = useState<User[]>([])
  const [map, setMap] = useState<MapLeaf>()
  const [geojson, setGeojson] = useState<any>()
  const [marker, setMarker] = useState<any>()

  const openModal = (t: string) => {
    setType(t)
    setModal(true)
  }

  const loadCountries = async () => {
    setCountries(await getCountries())
  }

  const loadTypes = async () => {
    setTypes(await getProjectTypes())
  }

  const loadProjectDevelopers = async () => {
    setProjectDevelopers(await getProjectDevelopers())
  }

  const loadWembers = async () => {
    setWembers(await getWembers())
  }

  useEffect(() => {
    loadCountries()
    loadTypes()
    loadProjectDevelopers()
    loadWembers()
  }, [])

  const closeModal = () => {
    setModal(false)
    if (typeModal === 'projectType') { loadTypes() }
    if (typeModal === 'projectDeveloper') { loadProjectDevelopers() }
  }

  useEffect(() => {
    if (form.getFieldValue('markerLocation') && !marker) setMarker(JSON.parse(form.getFieldValue('markerLocation')))
  }, [form.getFieldValue('markerLocation')])

  useEffect(() => {
    if (form.getFieldValue('shape') && !geojson) setGeojson(form.getFieldValue('shape'))
  }, [form.getFieldValue('shape')])

  const retrieveGeojson = async (shapefile: File) => {
    const json = await getGeojson({ shapefile })
    setGeojson(json)
  }

  useEffect(() => {
    const shape = form.getFieldValue('shapefile')
    if (shape) {
      retrieveGeojson(shape?.file?.originFileObj)
    }
  }, [form.getFieldValue('shapefile')])

  useEffect(() => {
    if (geojson) {
      map?.flyTo(getParcelCenter(geojson), 13, { duration: 0.5 })
    }
  }, [geojson])

  useEffect(() => {
    if (!map) return
    map.on('click', (e) => {
      // @ts-ignore
      setMarker(e.latlng)
      // @ts-ignore
      form.setFieldsValue({ markerLocation: JSON.stringify(e.latlng) })
    })
  }, [map])

  function beforeUpload(file:any) {
    if (!file.type && file.name.split('.')[file.name.split('.').length - 1] === 'geojson') return true
    const isType = ['application/zip', 'application/json'].includes(file.type)
    if (!isType) {
      // setError(t('wrongType'))
      return Upload.LIST_IGNORE
    }
    // setError('')
    return isType
  }

  return (
    <Form.Item>
      {isOpen && <CreateEditContentModal isOpen={isOpen} setIsOpen={closeModal} type={typeModal} />}
      <Form.Item label="Project name" name="name">
        <Input
          placeholder="Insert name"
          allowClear
        />
      </Form.Item>
      <div className="title-button">
        <div>Project developer</div>
        <PlusCircleOutlined onClick={() => openModal('projectDeveloper')} style={{ fontSize: 18 }} />
      </div>
      <Form.Item name="projectDeveloperId">
        <Select
          allowClear
          placeholder="-- Select --"
          showSearch
          filterOption={(input, option) => {
            if (!option?.children) return true
            return option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
          }}
        >
          {projectDevelopers.map((dev) => (
            <Select.Option key={dev.id} value={dev.id}>{dev.name}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Project area"
        wrapperCol={{ offset: 1 }}
      >
        <Form.Item
          label="Country"
          name="countryId"
        >
          <Select
            allowClear
            placeholder="-- Select --"
            showSearch
            filterOption={(input, option) => {
              if (!option?.children) return true
              return option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
            }}
          >
            {countries.map((country) => (
              <Select.Option key={country.id} value={country.id}>{country.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Shapefile"
          name="shapefile"
        >
          <Upload
            listType="picture"
            action={getUploadUrl()}
            headers={getUploadHeader()}
            beforeUpload={(e) => beforeUpload(e)}
            maxCount={1}
          >
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        </Form.Item>
        <i className="error">
          <WarningOutlined style={{ fontSize: 30 }} />
          {' '}
          Refreshing or leaving the project will delete unsaved shapefile
        </i>
        {geojson ? (
          <Form.Item
            label="Marker location"
            name="markerLocation"
            className="mt-3"
          >
            <div style={{ height: 400, width: '100%' }}>
              <MapContainer
                center={getParcelCenter(geojson)}
                zoom={13}
                zoomControl={false}
                whenCreated={setMap}
              >
                <TileLayer
                  url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                  subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                />
                {renderParcel(geojson)}
                {marker
                  ? <Marker position={marker || ''} /> : null}
                <ZoomControl position="topright" />
              </MapContainer>
            </div>
          </Form.Item>
        ) : null}
      </Form.Item>
      <div className="title-button">
        <div>Project type</div>
        <PlusCircleOutlined onClick={() => openModal('projectType')} style={{ fontSize: 18 }} />
      </div>
      <Form.Item name="projectTypeId">
        <Select
          placeholder="-- Select --"
          allowClear
          showSearch
          filterOption={(input, option) => {
            if (!option?.children) return true
            return option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
          }}
        >
          {types.map((type) => (
            <Option key={type.id} value={type.id}>{type.name}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Account owner" name="ownerId">
        <Select
          allowClear
          placeholder="-- Select --"
          showSearch
          filterOption={(input, option) => {
            if (!option?.children) return true
            return option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
          }}
        >
          {wembers.map((dev) => (
            <Select.Option key={dev.id} value={dev.id}>{dev.name}</Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Form.Item>
  )
}

export default ProjectDetails

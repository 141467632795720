import api, { apiFormData } from './index'

export const getWembers = async () => {
  const response = await api.get('/admin/wembers')
  return response.data
}

export const getWember = async (id: number) => {
  const response = await api.get(`/admin/wembers/${id}`)
  return response.data
}

export const updateWember = async (request: any, id: number) => {
  const response = await api.patch(`/admin/wembers/${id}`, request)
  return response.data
}

export const updateAvatar = async (request: any, id: number) => {
  const formData = new FormData()
  Object.keys(request).forEach((key) => {
    if (request[key] !== 'undefined' && request[key] !== undefined) { formData.append(key, request[key]) }
  })

  const response = await apiFormData.post(`/avatar/${id}`, formData)
  return response.data
}

export const deleteAvatar = async (id: number) => {
  const response = await api.delete(`/avatar/${id}`)
  return response.data
}

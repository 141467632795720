import { Request, TranslationRequest } from '../../types/service'
import api from '../index'

export const getBiologicalSpeciesAll = async () => {
  try {
    const response = await api.get('/biologicalSpecies')
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const createBiologicalSpecies = async (request: Request) => {
  try {
    const response = await api.post('/biologicalSpecies', request)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getBiologicalSpecies = async (id: number) => {
  try {
    const response = await api.get(`/biologicalSpecies/${id}`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const updateBiologicalSpecies = async (request: Request, id: number) => {
  try {
    const response = await api.patch(`/biologicalSpecies/${id}`, request)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getBiologicalSpeciesTranslations = async (biologicalSpeciesId: number) => {
  try {
    const response = await api.get(`/biologicalSpecies/${biologicalSpeciesId}/biologicalSpeciesTranslations`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const createBiologicalSpeciesTranslation = async (
  biologicalSpeciesId: number,
  request: TranslationRequest,
) => {
  try {
    const response = await api.post(`/biologicalSpecies/${biologicalSpeciesId}/biologicalSpeciesTranslations`, request)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getBiologicalSpeciesTranslation = async (biologicalSpeciesId: number, id: number) => {
  try {
    const response = await api.get(`/biologicalSpecies/${biologicalSpeciesId}/biologicalSpeciesTranslations/${id}`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const updateBiologicalSpeciesTranslation = async (
  biologicalSpeciesId: number,
  id: number,
  request: TranslationRequest,
) => {
  try {
    const response = await
    api.patch(`/biologicalSpecies/${biologicalSpeciesId}/biologicalSpeciesTranslations/${id}`, request)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const deleteBiologicalSpeciesTranslation = async (biologicalSpeciesId: number, id: number) => {
  try {
    const response = await api.delete(`/biologicalSpecies/${biologicalSpeciesId}/biologicalSpeciesTranslations/${id}`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getProjectBiologicalSpeciesAll = async (projectId: number, biologicalSpeciesId: number) => {
  try {
    const response = await api.get(`/projects/${projectId}/biologicalSpecies/${biologicalSpeciesId}`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const createProjectBiologicalSpecies = async (projectId: number, biologicalSpeciesId: number) => {
  try {
    const response = await api.post(`/projects/${projectId}/biologicalSpecies/${biologicalSpeciesId}`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getProjectBiologicalSpecies = async (projectId: number, biologicalSpeciesId: number, id: number) => {
  try {
    const response = await api.get(`/projects/${projectId}/biologicalSpecies/${biologicalSpeciesId}/${id}`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const updateProjectBiologicalSpecies = async (projectId: number, biologicalSpeciesId: number, id: number) => {
  try {
    const response = await api.patch(`/projects/${projectId}/biologicalSpecies/${biologicalSpeciesId}/${id}`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const deleteProjectBiologicalSpecies = async (projectId: number, biologicalSpeciesId: number, id: number) => {
  try {
    const response = await api.delete(`/projects/${projectId}/biologicalSpecies/${biologicalSpeciesId}/${id}`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

import { TextRequest } from '../../types/service'
import api from '../index'

export const getProjectTexts = async (projectId: number) => {
  try {
    const response = await api.get(`/projects/${projectId}/projectTexts`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const createProjectText = async (projectId: number, request: TextRequest) => {
  try {
    const response = await api.post(`/projects/${projectId}/projectTexts`, request)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getProjectText = async (projectId: number, id: number) => {
  try {
    const response = await api.get(`/projects/${projectId}/projectTexts/${id}`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const updateProjectText = async (projectId: number, request: TextRequest, id: number) => {
  try {
    const response = await api.patch(`/projects/${projectId}/projectTexts/${id}`, request)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

import { Request, TranslationRequest } from '../../types/service'
import api from '../index'

export const getBenefits = async () => {
  try {
    const response = await api.get('/benefits')
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const createBenefit = async (request: Request) => {
  try {
    const response = await api.post('/benefits', request)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getBenefit = async (id: number) => {
  try {
    const response = await api.get(`/benefits/${id}`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const updateBenefit = async (request: Request, id: number) => {
  try {
    const response = await api.patch(`/benefits/${id}`, request)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getBenefitTranslations = async (benefitId: number) => {
  try {
    const response = await api.get(`/benefits/${benefitId}/benefitTranslations`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const createBenefitTranslation = async (benefitId: number, request: TranslationRequest) => {
  try {
    const response = await api.post(`/benefits/${benefitId}/benefitTranslations`, request)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getBenefitTranslation = async (benefitId: number, id: number) => {
  try {
    const response = await api.get(`/benefits/${benefitId}/benefitTranslations/${id}`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const updateBenefitTranslation = async (benefitId: number, id: number, request: TranslationRequest) => {
  try {
    const response = await api.patch(`/benefits/${benefitId}/benefitTranslations/${id}`, request)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const deleteBenefitTranslation = async (benefitId: number, id: number) => {
  try {
    const response = await api.delete(`/benefits/${benefitId}/benefitTranslations/${id}`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getProjectBenefits = async (projectId: number, benefitId: number) => {
  try {
    const response = await api.get(`/projects/${projectId}/benefits/${benefitId}`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const createProjectBenefit = async (projectId: number, benefitId: number) => {
  try {
    const response = await api.post(`/projects/${projectId}/benefits/${benefitId}`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getProjectBenefit = async (projectId: number, benefitId: number, id: number) => {
  try {
    const response = await api.get(`/projects/${projectId}/benefits/${benefitId}/${id}`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const updateProjectBenefit = async (projectId: number, benefitId: number, id: number) => {
  try {
    const response = await api.patch(`/projects/${projectId}/benefits/${benefitId}/${id}`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const deleteProjectBenefit = async (projectId: number, benefitId: number, id: number) => {
  try {
    const response = await api.delete(`/projects/${projectId}/benefits/${benefitId}/${id}`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

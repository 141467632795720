import React from 'react'
import { Routes, Route, Navigate, Outlet } from 'react-router-dom'

import { ConfigProvider } from 'antd'
import frFR from 'antd/lib/locale/fr_FR'
import enUS from 'antd/lib/locale/en_US'

import { store } from './redux/store'
import { WILDSENSE_ROLES } from './types/user'

import Login from './pages/auth/Login'
import NotFound from './pages/NotFounds'
import AdminLayout from './components/layouts/AdminLayout'
import ProjectsPage from './pages/restoration/Projects'
import ArchivesPage from './pages/restoration/Archives'
import ProjectManagement from './pages/restoration/projects/ProjectManagement'
import SettingsPage from './pages/restoration/Settings'
import TransactionPage from './pages/restoration/Transactions'
import ContentManagement from './pages/restoration/ContentManagement'
import NewTransactionPage from './pages/restoration/transaction/NewTransaction'
import ArchivesPageTransaction from './pages/restoration/ArchivesTransactions'

const getLocale = () => {
  const { user } = store.getState().user

  if (user?.language === 'fr') return frFR
  return enUS
}

const AdminOutlet = () => {
  const { user } = store.getState().user
  if (!user || !user.status) {
    return <Navigate to="/login" />
  }

  return [WILDSENSE_ROLES.ADMIN, WILDSENSE_ROLES.WEMBER].includes(user!.status)
    ? (
      <ConfigProvider locale={getLocale()}>
        <AdminLayout><Outlet /></AdminLayout>
      </ConfigProvider>
    )
    : <Navigate to="/login" />
}

const Router = () => (
  <div style={{ flex: 1 }}>
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<NotFound />} />

      <Route path="/" element={<AdminOutlet />}>
        <Route path="/" element={<ProjectsPage />} />
        <Route path="projects" element={<ProjectsPage />} />
        <Route path="projects/archives" element={<ArchivesPage />} />
        <Route path="projects/:step" element={<ProjectManagement />} />
        <Route path="projects/:step/new" element={<ProjectManagement newProject />} />
        <Route path="projects/:step/:id" element={<ProjectManagement edit />} />
        <Route path="transactions" element={<TransactionPage />} />
        <Route path="transactions/archives" element={<ArchivesPageTransaction />} />
        <Route path="transactions/new" element={<NewTransactionPage />} />
        <Route path="transactions/:id" element={<NewTransactionPage edit />} />
        <Route path="settings" element={<SettingsPage />} />
        <Route path="content" element={<ContentManagement />} />
      </Route>
    </Routes>
  </div>
)

export default Router

import { TransactionRequest } from '../../types/service'
import api from '../index'

export const createTransaction = async (request: TransactionRequest) => {
  const response = await api.post('/transactions', request)
  return response.data
}

export const getTransactions = async () => {
  const response = await api.get('/transactions/')
  return response.data
}

export const getArchivedTransactions = async () => {
  const response = await api.get('/transactions/archived')
  return response.data
}

export const getTransaction = async (id: number) => {
  const response = await api.get(`/transactions/${id}`)
  return response.data
}

export const updateTransaction = async (request: TransactionRequest, id: number) => {
  try {
    const response = await api.patch(`/transactions/${id}`, request)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

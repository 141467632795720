import { DeveloperRequest } from '../../types/service'
import api from '../index'

export const getProjectDevelopers = async () => {
  try {
    const response = await api.get('/projectDevelopers')
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const createProjectDeveloper = async (request: DeveloperRequest) => {
  try {
    const response = await api.post('/projectDevelopers', request)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getProjectDeveloper = async (id: number) => {
  try {
    const response = await api.get(`/projectDevelopers/${id}`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const updateProjectDeveloper = async (request: DeveloperRequest, id: number) => {
  try {
    const response = await api.patch(`/projectDevelopers/${id}`, request)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

import React, { useEffect, useState } from 'react'
import {
  Button,
  Input,
  Form,
  Modal,
  Dropdown,
  Menu,
  Row,
  Col,
} from 'antd'
import { useTranslation } from 'react-i18next'

import { UploadFile } from 'antd/lib/upload/interface'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { getApiAssets } from '../../../services'
import AvatarUpload from '../../../components/AvatarUpload'
import {
  createMilestone, createMilestoneTranslation,
  deleteMilestoneTranslation, updateMilestone, updateMilestoneTranslation,
} from '../../../services/project/milestoneService'
import {
  createBenefit, createBenefitTranslation,
  deleteBenefitTranslation, updateBenefit, updateBenefitTranslation,
} from '../../../services/project/benefitService'
import {
  createTreeSpecies, createTreeSpeciesTranslation,
  deleteTreeSpeciesTranslation, updateTreeSpecies, updateTreeSpeciesTranslation,
} from '../../../services/project/treeSpeciesService'
import {
  createBiologicalSpecies, createBiologicalSpeciesTranslation,
  deleteBiologicalSpeciesTranslation, updateBiologicalSpecies, updateBiologicalSpeciesTranslation,
} from '../../../services/project/biologicalSpeciesService'
import {
  createProjectType, createProjectTypeTranslation,
  deleteProjectTypeTranslation, updateProjectType, updateProjectTypeTranslation,
} from '../../../services/project/projectTypeService'
import { createProjectDeveloper, updateProjectDeveloper } from '../../../services/project/projectDeveloperService'
import { languages } from '../../../types/project'

export interface CreateEditContentModalProps {
  isOpen: boolean,
  setIsOpen: any,
  type: string,
  initItem?: any
}

const CreateEditContentModal = (
  { isOpen, setIsOpen, type, initItem = null }: CreateEditContentModalProps,
) => {
  const { t } = useTranslation('', { keyPrefix: 'upload' })
  const [error, setError] = useState('')
  const [icons, setIcons] = useState<UploadFile[] | null>([])
  const [usedLanguages, setUsedLanguages] = useState<string[]>([])
  const [form] = Form.useForm<any>()

  useEffect(() => {
    if (!initItem) setIcons([])
    else {
      if (initItem?.iconUrl) {
        setIcons([{
          uid: initItem.iconUrl + Math.random(),
          name: initItem.iconUrl,
          thumbUrl: getApiAssets(initItem.iconUrl),
        }])
      } else {
        setIcons([])
      }
      setUsedLanguages(initItem?.translations?.map((tr: any) => tr.language))
    }
  }, [initItem])

  const createOrEdit = async (values: any) => {
    const promiseCollection: Promise<any>[] = []
    setError('')
    let newList: typeof initItem = initItem
    if (type === 'milestone' && icons && !icons.length) setError(t('missingIcon'))
    try {
      if (type === 'milestone' && values.name) {
        const icon = icons?.length && icons[0].originFileObj ? icons[0].originFileObj : null
        if (!initItem && icon) {
          newList = await createMilestone({ ...values, icon })
        } else if (initItem && (icon || (values.name !== initItem.name))) {
          newList = await updateMilestone({ ...values, icon }, initItem.id)
        }
        values?.translations?.forEach((v: any) => {
          const found = initItem?.translations?.find((i: any) => i.language === v.language)
          if (!found) {
            promiseCollection.push(createMilestoneTranslation(newList.id, v))
          } else if (v.name !== found.name) {
            promiseCollection.push(updateMilestoneTranslation(newList.id, found.id, v))
          }
        })
        initItem?.translations?.forEach((v: any) => {
          const found = values?.translations?.find((i: any) => i.language === v.language)
          if (!found) {
            promiseCollection.push(deleteMilestoneTranslation(newList.id, v.id))
          }
        })
      }
      if (type === 'benefit' && values.name) {
        if (!initItem) {
          newList = await createBenefit({ ...values })
        } else if (initItem && (values.name !== initItem.name)) {
          newList = await updateBenefit({ ...values }, initItem.id)
        }
        values?.translations?.forEach((v: any) => {
          const found = initItem?.translations?.find((i: any) => i.language === v.language)
          if (!found) {
            promiseCollection.push(createBenefitTranslation(newList.id, v))
          } else if (v.name !== found.name) {
            promiseCollection.push(updateBenefitTranslation(newList.id, found.id, v))
          }
        })
        initItem?.translations?.forEach((v: any) => {
          const found = values?.translations?.find((i: any) => i.language === v.language)
          if (!found) {
            promiseCollection.push(deleteBenefitTranslation(newList.id, v.id))
          }
        })
      }
      if (type === 'treeSpecies' && values.name) {
        if (!initItem) {
          newList = await createTreeSpecies({ ...values })
        } else if (initItem && (values.name !== initItem.name)) {
          newList = await updateTreeSpecies({ ...values }, initItem.id)
        }
        values?.translations?.forEach((v: any) => {
          const found = initItem?.translations?.find((i: any) => i.language === v.language)
          if (!found) {
            promiseCollection.push(createTreeSpeciesTranslation(newList.id, v))
          } else if (v.name !== found.name) {
            promiseCollection.push(updateTreeSpeciesTranslation(newList.id, found.id, v))
          }
        })
        initItem?.translations?.forEach((v: any) => {
          const found = values?.translations?.find((i: any) => i.language === v.language)
          if (!found) {
            promiseCollection.push(deleteTreeSpeciesTranslation(newList.id, v.id))
          }
        })
      }
      if (type === 'biologicalSpecies' && values.name) {
        if (!initItem) {
          newList = await createBiologicalSpecies({ ...values })
        } else if (initItem && (values.name !== initItem.name)) {
          newList = await updateBiologicalSpecies({ ...values }, initItem.id)
        }
        values?.translations?.forEach((v: any) => {
          const found = initItem?.translations?.find((i: any) => i.language === v.language)
          if (!found) {
            promiseCollection.push(createBiologicalSpeciesTranslation(newList.id, v))
          } else if (v.name !== found.name) {
            promiseCollection.push(updateBiologicalSpeciesTranslation(newList.id, found.id, v))
          }
        })
        initItem?.translations?.forEach((v: any) => {
          const found = values?.translations?.find((i: any) => i.language === v.language)
          if (!found) {
            promiseCollection.push(deleteBiologicalSpeciesTranslation(newList.id, v.id))
          }
        })
      }
      if (type === 'projectType' && values.name) {
        if (!initItem) {
          newList = await createProjectType({ ...values })
        } else if (initItem && (values.name !== initItem.name)) {
          newList = await updateProjectType({ ...values }, initItem.id)
        }
        values?.translations?.forEach((v: any) => {
          const found = initItem?.translations?.find((i: any) => i.language === v.language)
          if (!found) {
            promiseCollection.push(createProjectTypeTranslation(newList.id, v))
          } else if (v.name !== found.name) {
            promiseCollection.push(updateProjectTypeTranslation(newList.id, found.id, v))
          }
        })
        initItem?.translations?.forEach((v: any) => {
          const found = values?.translations?.find((i: any) => i.language === v.language)
          if (!found) {
            promiseCollection.push(deleteProjectTypeTranslation(newList.id, v.id))
          }
        })
      }
      const isIdentical = () => {
        let identical = true
        Object.keys(values).forEach((key) => { if (initItem[key] !== values[key]) identical = false })
        return identical
      }
      if (type === 'projectDeveloper' && values.name) {
        if (!initItem) {
          newList = await createProjectDeveloper({ ...values })
        } else if (initItem && !isIdentical()) {
          newList = await updateProjectDeveloper({ ...values }, initItem.id)
        }
      }
      await Promise.all(promiseCollection)
      if (promiseCollection.length || newList) {
        setIcons([])
        setIsOpen(false)
      }
    } catch (e) {
      setError('Error')
    }
  }

  return (
    <Modal
      centered
      visible={isOpen}
      onOk={() => setIsOpen(false)}
      onCancel={() => setIsOpen(false)}
      footer={null}
      destroyOnClose
      title={initItem ? `Edit ${initItem.name}` : `Create new ${type}`}
    >
      <Form
        name="createOrEdititem"
        form={form}
        initialValues={initItem ? { ...initItem } : { }}
        onFinish={createOrEdit}
        layout="vertical"
        onValuesChange={(changes, values) => {
          setUsedLanguages(values?.translations?.map((tr: any) => (tr?.language)) || [])
        }}
      >
        <Row>
          {type === 'milestone' && (
            <Col span={7} style={{ lineHeight: 1.25 }}>
              <Row style={{ fontSize: 17 }}>
                <Col className="error" span={2}>*</Col>
                <Col> Icon </Col>
              </Row>
              <AvatarUpload
                type="icon"
                files={icons}
                setFileList={setIcons}
                onRemove={() => null}
                className="mt-0"
              />
            </Col>
          )}
          <Col flex="auto">
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true }]}
            >
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
        </Row>
        {type === 'projectDeveloper'
        && (
          <>
            <Form.Item label="Email" name="email" rules={[{ required: true }]}>
              <Input placeholder="Insert email" />
            </Form.Item>
            <Form.Item label="Phone" name="phone" rules={[{ required: true }]}>
              <Input placeholder="Insert phone" />
            </Form.Item>
            <Form.Item label="Address" name="address" rules={[{ required: true }]}>
              <Input placeholder="Insert address" />
            </Form.Item>
            <Form.Item label="City" name="city" rules={[{ required: true }]}>
              <Input placeholder="Insert city" />
            </Form.Item>
            <Form.Item label="Zipcode" name="zipcode" rules={[{ required: true }]}>
              <Input placeholder="Insert zipcode" />
            </Form.Item>
            <Form.Item label="Country" name="country" rules={[{ required: true }]}>
              <Input placeholder="Insert country" />
            </Form.Item>
          </>
        )}
        <Form.List name="translations">
          {(fields, { add, remove }) => (
            <div>
              {fields.map(({ key, name, ...restField }) => (
                <Row key={key} style={{ display: 'flex', marginBottom: 8, width: '100%' }}>
                  <Col span={22}>
                    <Form.Item
                      name={[name, 'name']}
                      {...restField}
                      rules={[{ required: true }]}
                      label={
                        languages.find((l) => l.language === form.getFieldValue('translations')
                          ?.[name]?.language)?.name
                      }
                    >
                      <Input placeholder="Insert translation" />
                    </Form.Item>
                  </Col>
                  <Col span={2} style={{ textAlign: 'right', paddingTop: 38 }}>
                    <MinusCircleOutlined onClick={() => remove(name)} style={{ fontSize: 20 }} />
                  </Col>
                </Row>
              ))}
              {languages?.length > usedLanguages?.length && type !== 'projectDeveloper' ? (
                <Form.Item>
                  <Dropdown overlay={(
                    <Menu onClick={({ key }) => {
                      add({ language: key })
                    }}
                    >
                      {languages.filter((l) => !usedLanguages.find((ul) => ul === l?.language))
                        ?.map((l) => (
                          <Menu.Item key={l.language}>{l.name}</Menu.Item>
                        ))}
                    </Menu>
                  )}
                  >
                    <Button block>
                      Add translation
                      <PlusOutlined />
                    </Button>
                  </Dropdown>
                </Form.Item>
              ) : null}
            </div>
          )}
        </Form.List>
        <Form.Item>
          <p className="error">{error}</p>
          <Button type="primary" htmlType="submit">
            {initItem ? 'Edit' : 'Create'}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

CreateEditContentModal.defaultProps = { initItem: null }

export default CreateEditContentModal

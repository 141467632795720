import React from 'react'
import { Button, Row, Space } from 'antd'

interface StepsButtonsProps {
  onReset ?: () => void,
  onBack ?: () => void,
  onFinish ?: () => void,
  onNext ?: () => void,
  onSubmit ?: () => void,
  success: string,
  error: string,
  isLoading: boolean
}

const StepsButtons = (props : StepsButtonsProps) => {
  const { onReset, onBack, onFinish, onNext, onSubmit, error, success, isLoading } = props
  return (
    <div>
      <div style={{ display: 'flex', gap: '8px', justifyContent: 'space-between' }}>
        <Space>
          {(onReset && (
            <Button type="default" shape="round" onClick={onReset} loading={isLoading}>Reset</Button>)
          ) || <Space />}
        </Space>
        <Space>
          {onBack && <Button type="default" shape="round" onClick={onBack} loading={isLoading}>Back</Button>}
          {onFinish && <Button type="default" shape="round" onClick={onFinish} loading={isLoading}>Save</Button>}
          {onNext && <Button type="primary" shape="round" onClick={onNext} loading={isLoading}>Next</Button>}
          {onSubmit && <Button type="primary" shape="round" onClick={onSubmit} loading={isLoading}>Submit</Button>}
        </Space>
      </div>
      <Row className="mt-3">
        <p className="ml-auto mr-1 error">
          {error}
        </p>
      </Row>
      <Row>
        <p className="ml-auto mr-1 success">
          {success}
        </p>
      </Row>
    </div>
  )
}

StepsButtons.defaultProps = {
  onReset: undefined,
  onBack: undefined,
  onFinish: undefined,
  onNext: undefined,
  onSubmit: undefined,
}

export default StepsButtons

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Project, Language } from '../../types/project'
import { RootState } from '../store'

const english: Language = { name: 'English', language: 'en' }

export interface ProjectManagementState {
  project: Project | Partial<Project> | undefined,
  languages: Language[],
  language: Language,
}

const initialState: ProjectManagementState = {
  project: undefined,
  languages: [english],
  language: english,
}

export const userSlice = createSlice({
  name: 'projectManagement',
  initialState,
  reducers: {
    setProject: (state, action: PayloadAction<typeof state.project>) => {
      state.project = { ...state.project, ...action.payload }
    },
    resetProject: (state) => {
      state.project = undefined
    },
    setLanguages: (state, action: PayloadAction<typeof state.languages>) => {
      state.languages = [...action.payload]
      if (!state.languages.find((l) => l.name === state.language.name)) { state.language = english }
    },
    setLanguage: (state, action: PayloadAction<typeof state.language>) => {
      state.language = action.payload
    },
    resetLanguage: (state) => {
      state.language = english
    },
    resetLanguages: (state) => {
      state.languages = [english]
    },
  },
})

export const { setProject, setLanguage, setLanguages, resetLanguage, resetProject, resetLanguages } = userSlice.actions

export const getProject = (state: RootState) => state.projectManagement.project
export const getImgUpload = (state: RootState) => state.projectManagement.project?.imagesUrl
export const getLanguages = (state: RootState) => state.projectManagement.languages
export const getLanguage = (state: RootState) => state.projectManagement.language
export const getMilestones = (state: RootState) => state.projectManagement.project?.milestones

export default userSlice.reducer

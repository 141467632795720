import React, { useState } from 'react'
import { Upload } from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import { getUploadUrl, getUploadHeader } from '../services'

export interface AvatarUploadProps {
  files: any,
  setFileList: any,
  className?: string,
  onRemove: any
  type ?: string
}

const AvatarUpload = ({ files, setFileList, className, onRemove, type }: AvatarUploadProps) => {
  const { t } = useTranslation('', { keyPrefix: 'upload' })

  const [loadingImage, setLoadingImage] = useState(false)
  const [error, setError] = useState('')

  function beforeUpload(file:any) {
    if (type === 'icon') {
      const isJpgOrPng = file.type === 'image/svg+xml'
      if (!isJpgOrPng) {
        setError(t('isNotSvg'))
        return Upload.LIST_IGNORE
      }
      setError('')
      return isJpgOrPng
    }
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      setError(t('isNotJpgOrPng'))
      return Upload.LIST_IGNORE
    }
    setError('')
    return isJpgOrPng
  }

  const handleChange = ({ fileList }: {fileList: any}) => {
    let load = false
    fileList.map((f:any) => { if (f?.status === 'uploading') load = true })
    setLoadingImage(load)
    setFileList(fileList.filter((f:any) => f.status && f.status !== 'error'))
  }

  const uploadButton = (
    <div>
      {loadingImage ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>{t('uploadAvatar')}</div>
    </div>
  )

  return (
    <>
      <Upload
        listType="picture-card"
        multiple
        className={className}
        showUploadList={{ showPreviewIcon: false }}
        action={getUploadUrl()}
        headers={getUploadHeader()}
        beforeUpload={(e) => beforeUpload(e)}
        fileList={files}
        onChange={handleChange}
        maxCount={1}
        onRemove={onRemove}
      >
        {files.length >= 1 ? null : uploadButton}
      </Upload>
      <p className="error">
        {error}
      </p>
    </>
  )
}

AvatarUpload.defaultProps = { className: '', type: '' }

export default AvatarUpload

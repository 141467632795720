import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Card, Input, Row, Space, Table } from 'antd'
import { SearchOutlined, PlusOutlined, InboxOutlined, RightOutlined, DeleteOutlined, EditOutlined }
  from '@ant-design/icons'
import { getTransactions, updateTransaction } from '../../services/project/transactionService'
import { Transaction } from '../../types/transaction'
import { useAppSelector } from '../../redux/hooks'
import { getUser } from '../../redux/reducers/userReducer'
import HeaderWithAvatar from '../../components/HeaderAvatar'
import { formatDate } from '../../tools/dateTools'

const TransactionPage = () => {
  const navigate = useNavigate()
  const [data, setData] = useState<Transaction[]>([])
  const [originalData, setOriginalData] = useState<Transaction[]>([])
  const [search, setSearch] = useState('')
  const draftedTransaction = useAppSelector(getTransactions)
  const user = useAppSelector(getUser)

  const clickHandler = (t: Transaction) => {
    navigate(`/transactions/${t.id}`)
  }

  const loadTransactions = async () => {
    const transactions = await getTransactions()
    setOriginalData(transactions)
    setData(transactions)
  }

  useEffect(() => {
    loadTransactions()
  }, [])

  useEffect(() => {
    const filteredData = originalData.filter((entry) => (
      entry.projectName?.toLowerCase().includes(search.toLowerCase())))
    setData(filteredData)
  }, [search])

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  const archiveTransaction = async (p: Transaction) => {
    await updateTransaction({ archived: true }, p.id)
    await loadTransactions()
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Project Name',
      dataIndex: 'projectName',
      key: 'projectName',
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: any) => (
        <Space size="middle">
          {formatDate(text)}
        </Space>
      ),
    },
    {
      title: 'Updated at',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text: any) => (
        <Space size="middle">
          {formatDate(text)}
        </Space>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      render: (text: typeof data[number], record: typeof data[number]) => (
        <Space size="middle">
          <Button
            type="default"
            icon={<DeleteOutlined />}
            onClick={(e) => {
              e.stopPropagation()
              archiveTransaction(record)
            }}
          />
          <Button
            type="default"
            icon={<EditOutlined />}
            onClick={() => clickHandler(record)}
          />
        </Space>
      ),
    },
  ]

  return (
    <>
      <HeaderWithAvatar>
        <h3 className="pt-1">
          <b>
            Welcome back
            {' '}
            {user?.name}
            {' '}
            !
          </b>
        </h3>
      </HeaderWithAvatar>
      <Card>
        <Row justify="end">
          <Space className="mb-5">
            <Input
              suffix={<SearchOutlined />}
              placeholder="Search..."
              value={search}
              onChange={handleSearch}
            />
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              onClick={() => navigate('/transactions/new')}
            >
              New
            </Button>
          </Space>
        </Row>
        <Table
          columns={columns}
          dataSource={data}
        />
        <Button
          shape="round"
          icon={<InboxOutlined />}
          onClick={() => navigate('/transactions/archives')}
        >
          Archives
        </Button>
      </Card>
    </>
  )
}

export default TransactionPage

import React from 'react'
import { Input, Form } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import { WarningOutlined } from '@ant-design/icons'

import ImageUploader from '../../../components/ImageUploader'
import { useAppSelector } from '../../../redux/hooks'
import { getLanguage } from '../../../redux/reducers/projectManagementReducer'
import { ProjectRequest, TextTypes } from '../../../types/service'

const { TextArea } = Input

const ProjectOverview = ({ form }: {form: FormInstance<ProjectRequest | undefined>}) => {
  const selectedLanguage = useAppSelector(getLanguage)

  const getFileList = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  return (
    <Form.Item>
      <Form.Item
        label="Why we love this project"
        name={['newTexts', TextTypes.WHY_WE_LOVE, selectedLanguage.language]}
        extra={selectedLanguage.language !== 'en' ? form.getFieldValue('newTexts')?.[TextTypes.WHY_WE_LOVE]?.en : ''}
      >
        <TextArea
          rows={4}
          allowClear
          disabled={selectedLanguage.language !== 'en'
          && !form.getFieldValue('newTexts')?.[TextTypes.WHY_WE_LOVE]?.en}
        />
      </Form.Item>
      <Form.Item
        label="About the caregivers"
        name={['newTexts', TextTypes.CAREGIVERS, selectedLanguage.language]}
        extra={selectedLanguage.language !== 'en' ? form.getFieldValue('newTexts')?.[TextTypes.CAREGIVERS]?.en : ''}
      >
        <TextArea
          rows={4}
          allowClear
          disabled={selectedLanguage.language !== 'en'
          && !form.getFieldValue('newTexts')?.[TextTypes.CAREGIVERS]?.en}
        />
      </Form.Item>
      <Form.Item
        name="images"
        label="Images"
        valuePropName="fileList"
        getValueFromEvent={getFileList}
      >
        <ImageUploader onChange={() => null} />
      </Form.Item>
      <i className="error">
        <WarningOutlined style={{ fontSize: 30 }} />
        {' '}
        Refreshing or leaving the project will delete all unsaved images
      </i>
    </Form.Item>
  )
}

export default ProjectOverview

import React, { CSSProperties, useEffect, useState } from 'react'
import UploadList from 'antd/es/upload/UploadList'
import { UploadFile } from 'antd/es/upload/interface'
import { Upload, Col, Row } from 'antd'
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  Droppable,
  DropResult,
  NotDraggingStyle,
} from 'react-beautiful-dnd'
import { UploadProps } from 'antd/lib/upload'
import { PictureOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons'
import { getUploadUrl, getUploadHeader } from '../services'
import { ReactComponent as DragIcon } from '../assets/icons/drag.svg'
// import { ReactComponent as EditIcon } from '../assets/icons/edit.svg'

const grid = 8

// eslint-disable-next-line no-unused-vars
const getListStyle = (isDraggingOver: boolean): CSSProperties => ({
  background: '#f9f9f9',
  display: 'flex',
  flexWrap: 'wrap',
  padding: grid * 2,
  overflow: 'auto',
  height: '100%',
})

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
): CSSProperties => ({
  userSelect: 'none',
  padding: 0,
  margin: 4,
  display: 'flex',
  placeItems: 'center',
  background: 'transparent',
  ...draggableStyle,
})

type Props = {
  // eslint-disable-next-line no-unused-vars
  onChange: (files: UploadFile[]) => void
} & UploadProps

const ImageUploader = ({ onChange, ...props } : Props) => {
  const [fileList, setFileList] = useState<UploadFile[]>(props.fileList || [])
  const [canClickOnUpload, setCanClickOnUpload] = useState(true)

  useEffect(() => {
    if (props.fileList && !fileList.length && (fileList.length !== props.fileList.length)) setFileList(props.fileList)
  }, [props.fileList])

  useEffect(() => {
    onChange(fileList.map((f) => ({ ...f })))
  }, [fileList])

  const onRemove = (file: UploadFile) => {
    setFileList(fileList.filter((item) => item.uid !== file.uid))
  }

  const reorder = (list: UploadFile[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = ({ source, destination }: DropResult) => {
    if (!destination) {
      return
    }

    const newFileList = reorder(
      fileList,
      source.index,
      destination.index,
    )
    setFileList(newFileList)
    setCanClickOnUpload(true)
  }

  return (
    <Upload
      {...props}
      fileList={fileList}
      showUploadList={false}
      onChange={(e) => setFileList(e.fileList)}
      action={getUploadUrl()}
      headers={getUploadHeader()}
      className="images-upload"
      openFileDialogOnClick={canClickOnUpload}
      id="uploader"
      multiple
    >
      {fileList && (
        <DragDropContext onDragEnd={onDragEnd} onDragStart={() => setCanClickOnUpload(false)}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {fileList.map((item, index) => (
                  <Draggable
                    key={item.uid}
                    draggableId={item.uid}
                    index={index}
                  >
                    {(provided2, snapshot2) => (
                      <div
                        ref={provided2.innerRef}
                        {...provided2.draggableProps}
                        {...provided2.dragHandleProps}
                        style={getItemStyle(
                          snapshot2.isDragging,
                          provided2.draggableProps.style,
                        )}
                      >
                        <DragIcon className="mr-2" />
                        <Col
                          className="image-uploader"
                          onClick={(e) => {
                            onRemove(item)
                            e.stopPropagation()
                          }}
                        >
                          <UploadList
                            locale={{ removeFile: 'remove' }}
                            showDownloadIcon={false}
                            showPreviewIcon={false}
                            listType="picture-card"
                            onRemove={onRemove}
                            items={[item]}
                          />
                        </Col>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                {!fileList || fileList.length < 1 ? (
                  <div style={{ width: '100%', alignSelf: 'center' }}>
                    <Col>
                      <Row justify="center">
                        <PictureOutlined style={{ fontSize: 30 }} />
                      </Row>
                      <Row justify="center" className="mt-3">
                        Drag & drop to upload
                      </Row>
                      <Row justify="center" className="mt-2">
                        or
                      </Row>
                      <Row justify="center" className="mt-2">
                        <b>
                          <UploadOutlined style={{ fontSize: 20 }} className="mr-2" />
                          Choose file
                        </b>
                      </Row>
                    </Col>
                  </div>
                )
                  : (
                    <div
                      style={{
                        width: 104,
                        height: 104,
                        borderRadius: 4,
                        border: '1px dashed #d9d9d9',
                        alignSelf: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        color: '#9e9e9e',
                      }}
                      className="ml-6"
                    >
                      <PlusOutlined style={{ fontSize: 25 }} className="mb-3" />
                      Add image
                    </div>
                  )}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </Upload>
  )
}

export default ImageUploader

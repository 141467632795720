/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react'
import { Card, Steps, Form } from 'antd'
import { UploadFile } from 'antd/lib/upload/interface'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import {
  getLanguages, getProject, resetProject, setLanguages,
  setProject,
} from '../../../redux/reducers/projectManagementReducer'
import StepsButtons from '../../../components/StepsButton'
import ProjectDetails from './ProjectDetails'
import ProjectOverview from './ProjectOverview'
import ProjectData from './ProjectData'
import ProjectMilestones from './ProjectMilestones'
import ProjectSummary from './ProjectSummary'
import HandleLanguage from '../../../components/HandleLanguage'
import { updateProject, getProject as getProjectService, createProject } from '../../../services/project/projectService'
import { ProjectRequest, TextRequest, TextTypes } from '../../../types/service'
import { createProjectProjectType, updateProjectProjectType } from '../../../services/project/projectTypeService'
import { createProjectText, updateProjectText } from '../../../services/project/projectTextService'
import {
  createProjectTreeSpecies, updateProjectTreeSpecies,
  deleteProjectTreeSpecies,
} from '../../../services/project/treeSpeciesService'
import {
  createProjectBiologicalSpecies, updateProjectBiologicalSpecies,
  deleteProjectBiologicalSpecies,
} from '../../../services/project/biologicalSpeciesService'
import {
  createProjectBenefit, updateProjectBenefit,
  deleteProjectBenefit,
} from '../../../services/project/benefitService'
import { Language, languages, Project, ProjectText } from '../../../types/project'
import {
  createProjectMilestone,
  deleteProjectMilestone, updateProjectMilestone,
} from '../../../services/project/milestoneService'
import HeaderWithAvatar from '../../../components/HeaderAvatar'

const { Step } = Steps

const detailsFields = [
  'name',
  'projectDeveloper',
  'countryId',
  'shapefile',
  'markerLocation',
  'projectTypeId',
  'ownerId',
]

const overviewFields = [
  'newTexts',
  'images',
]

const dataFields = [
  'price',
  'carbonCapacity',
  'newTexts',
  'biologicalSpecies',
  'treeSpecies',
  'benefits',
]

const milestoneFields = ['milestones']

const ProjectManagement = ({ edit = false, newProject = false }: {edit?: boolean, newProject ?: boolean}) => {
  const [current, setCurrent] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const project = useAppSelector(getProject)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const selectedLanguages = useAppSelector(getLanguages)
  const [form] = Form.useForm< ProjectRequest | undefined>()
  const id = parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 1], 10)
  const [projectData, setProjectData] = useState<any | undefined>()
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const hasId = () => (edit ? `/${id}` : '')

  const steps = [
    {
      title: 'Project details',
      content: <ProjectDetails form={form} />,
      fields: detailsFields,
      url: 'details',
    },
    {
      title: 'Project overview',
      content: <ProjectOverview form={form} />,
      fields: overviewFields,
      url: 'overview',
    },
    {
      title: 'Project data',
      content: <ProjectData form={form} />,
      fields: dataFields,
      url: 'data',
    },
    {
      title: 'Project milestones',
      content: <ProjectMilestones form={form} />,
      fields: milestoneFields,
      url: 'milestones',
    },
    {
      title: 'Summary',
      content: <ProjectSummary setCurrent={setCurrent} hasId={hasId} form={form} />,
      url: 'summary',
    },
  ]

  const resetMessages = () => {
    setError('')
    setSuccess('')
  }

  const prepareNewTextField = (field: ProjectText[]) => {
    if (field && field[0]) {
      const obj: any = {}
      field.forEach((val: ProjectText) => { obj[val.language] = val.text })
      return obj
    }
    return undefined
  }

  const loadProject = async (toLoad:number) => {
    setIsLoading(true)
    const data = await getProjectService(toLoad)
    if (data?.imagesUrl) {
      form.setFieldsValue({
        images: JSON.parse(data.imagesUrl).map((image: string) => ({
          uid: image + Math.random(),
          name: image,
          thumbUrl: image,
        })),
      })
    }
    form.setFieldsValue(data)
    dispatch(setProject(data))
    setProjectData(data)
    if (data.types && data.types[0]) form.setFieldsValue({ projectTypeId: data.types[0].projectTypeId })
    if (data.text) {
      const availableLanguages : Language[] = [{ language: 'en', name: 'English' }]
      data.text.forEach((text: ProjectText) => {
        if (!availableLanguages.find((a) => a.language === text.language)) {
          availableLanguages.push(languages.find((l) => l.language === text.language)!)
        }
      })
      if (availableLanguages.length > selectedLanguages.length) { dispatch(setLanguages(availableLanguages)) }
      const allTexts: {[x:string] : ProjectText[]} = {}
      Object.values(TextTypes).forEach((type: string) => {
        allTexts[type] = data.text.filter((text: any) => text.type === type)
      })
      const finalNewText: {[x:string]: {[x:string]: string}} = {}
      Object.entries(allTexts).forEach(
        ([typeName, type]) => { finalNewText[typeName] = prepareNewTextField(type) },
      )
      form.setFieldsValue({ newTexts: finalNewText })
      // @ts-ignore
      dispatch(setProject({ newTexts: finalNewText }))
    }
    if (data.treeSpecies && data.treeSpecies.length) {
      const allTreeSpecies: any[] = []
      Object.values(data.treeSpecies).forEach((value: any) => allTreeSpecies.push(JSON.stringify(value.treeSpeciesId)))
      form.setFieldsValue({ treeSpecies: allTreeSpecies })
    }
    if (data.biologicalSpecies && data.biologicalSpecies.length) {
      const allBiologicalSpecies: any[] = []
      Object.values(data.biologicalSpecies)
        .forEach((value: any) => allBiologicalSpecies.push(JSON.stringify(value.biologicalSpeciesId)))
      form.setFieldsValue({ biologicalSpecies: allBiologicalSpecies })
    }
    if (data.benefits && data.benefits.length) {
      const allBenefits: any[] = []
      Object.values(data.benefits).forEach((value: any) => allBenefits.push(JSON.stringify(value.benefitId)))
      form.setFieldsValue({ benefits: allBenefits })
    }
    setIsLoading(false)
  }

  useEffect(() => {
    const curr = window.location.pathname.split('/')[2]
    setCurrent(steps.findIndex((step) => step.url === curr))
    if (edit) {
      loadProject(id)
    } else if (newProject) {
      form.resetFields()
      dispatch(resetProject())
      navigate('/projects/details')
    } else {
      form.setFieldsValue(project)
    }
    resetMessages()
  }, [])

  const handleValue = (changedValues: any, values: any) => {
    form.setFieldsValue({ ...values })
    dispatch(setProject(form.getFieldsValue(true)))
    resetMessages()
  }

  const changePage = (i: number) => {
    navigate(`/projects/${steps[current + i].url}${hasId()}`)
    setCurrent((current + i) % steps.length)
    resetMessages()
  }

  const onReset = () => {
    form.resetFields(steps[current].fields)
    handleValue(null, form.getFieldsValue())
    resetMessages()
  }

  const onFinish = async (e = { submit: false }) => {
    const values = form.getFieldsValue(true)
    const images = form.getFieldValue('images')
    let savedProject: Project

    setIsLoading(true)
    resetMessages()
    try {
      if (edit) {
        savedProject = await updateProject({
          ...values,
          ...(values?.shapefile && { shapefile: values?.shapefile?.file?.originFileObj }),
          ...(values?.images && {
            imageOrder: JSON.stringify(images.map((img: any) => img.name)),
            images: images.filter((img: any) => img.originFileObj).map((image: UploadFile) => image.originFileObj),
            ...(e.submit && { submitted: true }),
          }),
        }, id)
      } else {
        savedProject = await createProject({
          ...values,
          ...(values?.shapefile && { shapefile: values?.shapefile?.file?.originFileObj }),
          ...(values?.images && {
            imageOrder: JSON.stringify(images.map((img: any) => img.name)),
            images: images.filter((img: any) => img.originFileObj).map((image: UploadFile) => image.originFileObj),
          }),
          ...(e.submit && { submitted: true }),
        })
      }
      if (values?.projectTypeId) {
        if (projectData?.types[0]) {
          await updateProjectProjectType(savedProject.id, values.projectTypeId, projectData.types[0].id)
        } else {
          await createProjectProjectType(savedProject.id, values.projectTypeId)
        }
      }
      const promiseCollection: Promise<any>[] = []
      Object.values(TextTypes).forEach((type: string) => {
        if (values?.newTexts?.[type]) {
          Object.keys(values.newTexts?.[type]).forEach((language: string) => {
            if (!values.newTexts[type][language]) return
            const request:TextRequest = {
              type,
              language,
              text: values.newTexts[type][language],
            }
            const found = projectData?.text.length
          && projectData?.text.find((text: TextRequest) => (text.type === type && text.language === language))
            if (found) {
              promiseCollection.push(updateProjectText(savedProject.id, request, found.id))
            } else {
              promiseCollection.push(createProjectText(savedProject.id, request))
            }
          })
        }
      })
      if (values?.treeSpecies && values?.treeSpecies[0]) {
        const olds = projectData?.treeSpecies || []
        const changes = []
        for (let i = 0; i < Math.max(values.treeSpecies.length, olds.length); i += 1) {
          changes.push({
            newValue: parseInt(values?.treeSpecies[i], 10)
          || null,
            oldValue: projectData?.treeSpecies[i] || null,
          })
        }
        changes.forEach(({ newValue, oldValue }) => {
          if (newValue) {
            if (!oldValue) {
              promiseCollection.push(createProjectTreeSpecies(savedProject.id, newValue))
            } else if (oldValue.treeSpeciesId !== newValue) {
              promiseCollection.push(
                updateProjectTreeSpecies(savedProject.id, newValue, oldValue.id),
              )
            }
          } else {
            promiseCollection.push(deleteProjectTreeSpecies(savedProject.id, oldValue.treeSpeciesId, oldValue.id))
          }
        })
      }
      if (values?.biologicalSpecies && values?.biologicalSpecies[0]) {
        const olds = projectData?.biologicalSpecies || []
        const changes = []
        for (let i = 0; i < Math.max(values.biologicalSpecies.length, olds.length); i += 1) {
          changes.push({
            newValue: parseInt(values?.biologicalSpecies[i], 10)
          || null,
            oldValue: projectData?.biologicalSpecies[i] || null,
          })
        }
        changes.forEach(({ newValue, oldValue }) => {
          if (newValue) {
            if (!oldValue) {
              promiseCollection.push(
                createProjectBiologicalSpecies(savedProject.id, newValue),
              )
            } else if (oldValue.biologicalSpeciesId !== newValue) {
              promiseCollection.push(
                updateProjectBiologicalSpecies(savedProject.id, newValue, oldValue.id),
              )
            }
          } else {
            promiseCollection.push(
              deleteProjectBiologicalSpecies(savedProject.id, oldValue.biologicalSpeciesId, oldValue.id),
            )
          }
        })
      }
      if (values?.benefits && values?.benefits[0]) {
        const olds = projectData?.benefits || []
        const changes = []
        for (let i = 0; i < Math.max(values.benefits.length, olds.length); i += 1) {
          changes.push({
            newValue: parseInt(values?.benefits[i], 10) || null,
            oldValue: projectData?.benefits[i] || null,
          })
        }
        changes.forEach(({ newValue, oldValue }) => {
          if (newValue) {
            if (!oldValue) {
              promiseCollection.push(createProjectBenefit(savedProject.id, newValue))
            } else if (oldValue.benefitId !== newValue) {
              promiseCollection.push(updateProjectBenefit(savedProject.id, newValue, oldValue.id))
            }
          } else {
            promiseCollection.push(deleteProjectBenefit(savedProject.id, oldValue.benefitId, oldValue.id))
          }
        })
      }
      if (values?.milestones && values?.milestones[0]) {
        const olds = projectData?.milestones || []
        const changes = []
        for (let i = 0; i < Math.max(values.milestones.length, olds.length); i += 1) {
          changes.push({ newValue: values?.milestones[i] || null, oldValue: projectData?.milestones[i] || null })
        }
        changes.forEach(({ newValue, oldValue }) => {
          if (newValue) {
            if (!oldValue) {
              promiseCollection.push(createProjectMilestone(savedProject.id, newValue.milestoneId, newValue))
            } else if (oldValue.id !== newValue.id) {
              promiseCollection.push(updateProjectMilestone(
                savedProject.id,
                newValue.milestoneId,
                oldValue.id,
                newValue,
              ))
            }
          } else {
            promiseCollection.push(deleteProjectMilestone(savedProject.id, oldValue.milestoneId, oldValue.id))
          }
        })
      }
      await Promise.all(promiseCollection)
      form.resetFields(['images'])
      loadProject(savedProject.id)
      navigate(`/projects/${steps[current].url}/${savedProject.id}`)
      setIsLoading(false)
      setSuccess(e.submit ? 'Project submitted successfully' : 'Project saved successfully')
    } catch (errorM) {
      console.error('ERROR SAVING', errorM)
      setIsLoading(false)
      setError(JSON.stringify(errorM))
    }
  }

  const onNext = () => {
    resetMessages()
    changePage(1)
  }

  const onBack = () => {
    resetMessages()
    changePage(-1)
  }

  const projectSubmittion = [
    'name', 'projectDeveloperId', 'countryId', 'markerLocation', 'projectTypeId', 'ownerId',
    'price', 'carbonCapacity',
  ]

  const onSubmit = () => {
    resetMessages()
    let subError = ''
    const vals = form.getFieldsValue(true)
    projectSubmittion.forEach((field) => {
      if (!vals[field] || vals[field] === '' || vals[field] === 0) {
        subError += `${field}, `
      }
    })
    if (!subError) onFinish({ submit: true })
    else setError(`Cannot submit, some fields are missing or incorrect: ${subError.slice(0, -2)}`)
  }

  const ManagementSteps = () => (
    <div className="mb-3 px-10">
      <Steps current={current}>
        {steps.map((item, i) => (
          <Step
            key={item.title}
            title={item.title}
            onClick={() => {
              navigate(`/projects/${steps[i].url}${hasId()}`)
              setCurrent(i)
            }}
          />
        ))}
      </Steps>
    </div>
  )

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
      <HeaderWithAvatar>
        <h3 className="pt-1">
          <b>
            Restoration /
          </b>
          {' '}
          Project Management
        </h3>
      </HeaderWithAvatar>
      <ManagementSteps />
      <Card
        title={steps[current].title}
        extra={<HandleLanguage />}
      >
        <Form
          form={form}
          name="wrap"
          onValuesChange={handleValue}
          layout="vertical"
        >
          {steps[current].content}
          <StepsButtons
            onFinish={onFinish}
            onReset={onReset}
            onNext={current + 1 < steps.length ? onNext : undefined}
            onBack={current > 0 ? onBack : undefined}
            onSubmit={current + 1 === steps.length ? onSubmit : undefined}
            success={success}
            error={error}
            isLoading={isLoading}
          />
        </Form>
      </Card>
    </div>
  )
}

ProjectManagement.defaultProps = { edit: false, newProject: false }

export default ProjectManagement

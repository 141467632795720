import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { User } from '../../types/user'
import { RootState } from '../store'

export interface UserState {
  user: User | undefined,
  token: string,
  tokenExpiresAt: string,
}

const initialState: UserState = { token: '', tokenExpiresAt: '', user: undefined }

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload
    },
    setTokenExpiresAt: (state, action: PayloadAction<string>) => {
      state.tokenExpiresAt = action.payload
    },
    logOut: (state) => {
      state.token = ''
      window.location.href = '/login'
    },
  },
})

export const { setUser, setToken, logOut, setTokenExpiresAt } = userSlice.actions

export const getUser = (state: RootState) => state.user.user
export const getToken = (state: RootState) => state.user.token
export const getTokenExpiresAt = (state: RootState) => state.user.tokenExpiresAt

export default userSlice.reducer

import api from '../index'

export const getCountries = async () => {
  try {
    const response = await api.get('/countries')
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getCountry = async (id: number) => {
  try {
    const response = await api.get(`/countries/${id}`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

import api from './index'

export const authenticate = async (request: any) => {
  const response = await api.post('/login', request)
  return response.data
}

export const passwordRecovery = async (request: any) => {
  const response = await api.post('/password/recovery', request)
  return response.data
}

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Card, Input, Row, Space } from 'antd'
import { SearchOutlined, PlusOutlined, InboxOutlined, RightOutlined } from '@ant-design/icons'
import Table from '../../components/Table'
import { getProjects, updateProject } from '../../services/project/projectService'
import { Project } from '../../types/project'
import { useAppSelector } from '../../redux/hooks'
import { getProject } from '../../redux/reducers/projectManagementReducer'
import { getUser } from '../../redux/reducers/userReducer'
import HeaderWithAvatar from '../../components/HeaderAvatar'

const projectsColumns = ['id', 'name', 'owner', 'updatedAt', 'lastEditedBy', 'visible', 'submitted', 'action']

const ProjectsPage = () => {
  const navigate = useNavigate()
  const [data, setData] = useState<Project[]>([])
  const [originalData, setOriginalData] = useState<Project[]>([])
  const [search, setSearch] = useState('')
  const draftedProject = useAppSelector(getProject)
  const user = useAppSelector(getUser)

  const loadProjects = async () => {
    const projects = await getProjects()
    setOriginalData(projects)
    setData(projects)
  }

  useEffect(() => {
    loadProjects()
  }, [])

  useEffect(() => {
    const filteredData = originalData.filter((entry) => (
      entry.name?.toLowerCase().includes(search.toLowerCase())
      || entry.owner?.name?.toLowerCase().includes(search.toLowerCase())
      || entry.lastEditedBy?.name?.toLowerCase().includes(search.toLowerCase())
    ))
    setData(filteredData)
  }, [search])

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  const handleToggleSwitch = async (p: Project) => {
    await updateProject({ visible: !p.visible }, p.id)
    await loadProjects()
  }

  const archiveProject = async (p: Project) => {
    await updateProject({ archived: true, visible: false }, p.id)
    await loadProjects()
  }

  return (
    <>
      <HeaderWithAvatar>
        <h3 className="pt-1">
          <b>
            Welcome back
            {' '}
            {user?.name}
            {' '}
            !
          </b>
        </h3>
      </HeaderWithAvatar>
      <Card>
        <Row justify="end">
          <Space>
            {draftedProject && !draftedProject.id && (
              <Row style={{ alignItems: 'center' }}>
                <p className="pt-3">You have an unsaved project. Do you want to continue editing this project ?</p>
                <Button
                  type="primary"
                  shape="round"
                  icon={<RightOutlined />}
                  onClick={() => navigate('/projects/details')}
                  className="ml-3"
                >
                  Continue
                </Button>
              </Row>
            )}
            <Input
              suffix={<SearchOutlined />}
              placeholder="Search..."
              value={search}
              onChange={handleSearch}
            />
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              onClick={() => navigate('/projects/details/new')}
            >
              New
            </Button>
          </Space>
        </Row>
        <Table
          data={data}
          cols={projectsColumns}
          type="project"
          onToggleSwitch={handleToggleSwitch}
          onClick={(p: Project) => navigate(`/projects/summary/${p.id}`)}
          onArchive={archiveProject}
        />
        <Button
          shape="round"
          icon={<InboxOutlined />}
          onClick={() => navigate('/projects/archives')}
        >
          Archives
        </Button>
      </Card>
    </>
  )
}

export default ProjectsPage

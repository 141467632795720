import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'

import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'
import Router from './Router'
import { store } from './redux/store'
import './translations/i18n'
import 'antd-css-utilities/utility.min.css'
import './styles/index.less'
import './styles/index.css'
import 'leaflet/dist/leaflet.css'

import pinIcon from './assets/icons/icon-pin.svg'

const { L } = window

const DefaultIcon = L.icon({
  iconUrl: pinIcon,
  shadowUrl: iconShadow,
  iconSize: [40, 40], // size of the icon
  iconAnchor: [20, 30], // point of the icon which will correspond to marker's location
  popupAnchor: [0, -51],
})

L.Marker.prototype.options.icon = DefaultIcon

const persistor = persistStore(store)

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router />
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
)

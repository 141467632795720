/* eslint-disable max-len */
export default {
  auth: {
    login: {
      login: 'Connexion',
      email: 'Email',
      password: 'Mot de passe',
      error: 'Email ou mot de passe invalide',
    },
    register: {},
    recovery: {},
  },

  table: {
    name: 'Nom',
    actions: 'Actions',
    status: 'Statut',
    company: 'Entreprise',
    email: 'Email',
    lastLogin: 'Dernière Connexion',
    admin: 'Administrateur',
    wember: 'Wember',
    manager: 'Manager',
    operator: 'Opérateur',
  },

  upload: {
    missingIcon: 'Le champ icône est obligatoire',
    isNotSvg: 'Veuillez sélectionner un .svg',
    isNotJpgOrPng: 'Veuillez sélectionner un .png ou .jpg',
    wrongType: 'Ce type de fichier n\'est pas accepté',
    tooBig: 'Le fichier est trop lourd',
    uploadAvatar: 'Charger un avatar',
    uploadIcon: 'Charger une icone',
    upload: 'Charger',
  },

  settings: {
    create: 'Ajouter un nouveau Wember',
    edit: 'Modification du Wember ',
    firstname: 'Prénom',
    lastname: 'Nom',
    email: 'Email',
    password: 'Mot de passe',
    phone: 'Téléphone',
    language: 'Langue',
    createWember: 'Ajouter le Wember',
    editWember: 'Modifier le Wember',
    dayFrom: 'Du',
    dayTo: 'Au',
    hourFrom: 'De',
    hourTo: 'À',
    day0: 'Lundi',
    day1: 'Mardi',
    day2: 'Mercredi',
    day3: 'Jeudi',
    day4: 'Vendredi',
    day5: 'Samedi',
    day6: 'Dimanche',
    status: 'Statut',
  },
}

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Card, Input, Row, Space } from 'antd'
import { LeftOutlined, SearchOutlined } from '@ant-design/icons'
import Table from '../../components/Table'
import { getArchivedProjects, updateProject } from '../../services/project/projectService'
import { Project } from '../../types/project'
import HeaderWithAvatar from '../../components/HeaderAvatar'
import { getTransactions } from '../../services/project/transactionService'

const projectsColumns = ['name', 'owner', 'updatedAt', 'lastUpdatedBy', 'submitted', 'actionArchives']

const ArchivesPage = () => {
  const navigate = useNavigate()
  const [data, setData] = useState<Project[]>([])
  const [originalData, setOriginalData] = useState<Project[]>([])
  const [search, setSearch] = useState('')

  const loadProjects = async () => {
    const projects = await getArchivedProjects()
    setOriginalData(projects)
    setData(projects)
  }

  useEffect(() => {
    loadProjects()
  }, [])

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  useEffect(() => {
    const filteredData = originalData.filter((entry) => (
      entry.name?.toLowerCase().includes(search.toLowerCase())
      || entry.owner?.name?.toLowerCase().includes(search.toLowerCase())
      || entry.lastEditedBy?.name?.toLowerCase().includes(search.toLowerCase())
    ))
    setData(filteredData)
  }, [search])

  const restoreProject = async (p: Project) => {
    await updateProject({ archived: false }, p.id)
    await loadProjects()
  }

  return (
    <>
      <HeaderWithAvatar>
        <div style={{ display: 'flex' }}>
          <Button
            icon={<LeftOutlined />}
            shape="circle"
            onClick={() => navigate('/projects')}
            className="mr-3"
            type="link"
          />
          <h3 className="pt-1">
            <b>
              Restoration /
            </b>
            {' '}
            Archives
          </h3>
        </div>
      </HeaderWithAvatar>
      <Card>
        <Row justify="end">
          <Space>
            <Input
              prefix={<SearchOutlined />}
              placeholder="Search"
              value={search}
              onChange={handleSearch}
            />
          </Space>
        </Row>

        <Table
          data={data}
          cols={projectsColumns}
          type="project"
          onClick={(p: Project) => navigate(`/projects/details/${p.id}`)}
          onRestore={restoreProject}
        />
      </Card>
    </>
  )
}

export default ArchivesPage

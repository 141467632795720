import React, { useState } from 'react'
import { Card, Row, Col, Menu, Drawer, Space } from 'antd'
import { useNavigate } from 'react-router-dom'
import { MenuOutlined, ProfileOutlined } from '@ant-design/icons'
import { useAppDispatch } from '../../redux/hooks'
import { logOut } from '../../redux/reducers/userReducer'
import { ReactComponent as WildsenseLogo } from '../../assets/wildsense_logo/logo_wildsense_white.svg'
import { ReactComponent as PM } from '../../assets/menu/project-management.svg'
import { ReactComponent as TransactionIcon } from '../../assets/menu/transaction.svg'
import { ReactComponent as Restoration } from '../../assets/menu/restoration.svg'
import { ReactComponent as Settings } from '../../assets/menu/settings.svg'
import { ReactComponent as Logout } from '../../assets/menu/logout.svg'

const { SubMenu } = Menu

const AdminLayout = ({ children }: {children: any}) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [drawer, setDrawer] = useState(false)

  const onMenuClick = (path:any) => {
    if (path.key === '/logout') {
      dispatch(logOut())
      return
    }
    setDrawer(false)
    navigate(`${path.key}`)
  }

  const MenuItems = () => (
    <>
      <WildsenseLogo className="ml-2" />
      <Menu
        mode="inline"
        defaultSelectedKeys={[`/${window.location.pathname.split('/')[1].split(/\d+/)[0]}` || '/projects']}
        className="flex-menu"
        defaultOpenKeys={['restoration']}
        onClick={onMenuClick}
        theme="dark"
      >
        <SubMenu key="restoration" icon={<Restoration />} title="Restoration">
          <Menu.Item key="/projects" icon={<PM />}>
            Project Management
          </Menu.Item>
          <Menu.Item key="/transactions" icon={<TransactionIcon />}>
            Transactions
          </Menu.Item>
          <Menu.Item key="/content" icon={<ProfileOutlined style={{ fontSize: 24 }} />}>
            Content Management
          </Menu.Item>
        </SubMenu>
        <Menu.ItemGroup className="mt-auto">
          <Menu.Item key="/settings" icon={<Settings />}>
            Settings
          </Menu.Item>
          <Menu.Item key="/logout" icon={<Logout />}>
            Logout
          </Menu.Item>
        </Menu.ItemGroup>
      </Menu>
    </>
  )

  return (
    <div style={{ backgroundColor: '#F6F5F1' }}>
      <Row className="wrapper">
        <Col className="my-3 ml-3 menu" style={{ height: '100vh', marginRight: 255 }}>
          <div className="sticky-menu">
            <Card
              size="small"
              style={{
                minHeight: '100%',
                backgroundColor: '#41693D',
                display: 'flex',
              }}
            >
              <MenuItems />
            </Card>
          </div>
        </Col>
        <div className="mt-3 mx-3 button-drawer" style={{ width: '100%' }}>
          <Card
            size="small"
            style={{ backgroundColor: '#41693D' }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              className="px-5"
            >
              <Space />
              <WildsenseLogo />
              <MenuOutlined
                style={{ fontSize: '26px', color: 'white' }}
                onClick={() => setDrawer(true)}
              />
            </div>
          </Card>
          <Drawer
            className="drawer"
            width="250px"
            bodyStyle={{
              backgroundColor: '#41693D',
              height: '100%',
              overflow: 'hidden',
              paddingRight: '10px',
              paddingLeft: '10px',
            }}
            headerStyle={{ display: 'none' }}
            placement="left"
            visible={drawer}
            onClose={() => setDrawer(false)}
          >
            <MenuItems />
          </Drawer>
        </div>
        <Col className="py-3 px-3 content" style={{ overflow: 'hidden' }}>
          {children}
        </Col>
      </Row>
    </div>
  )
}

export default AdminLayout

export function parseDate(dateStr: string) {
  if (!dateStr) {
    return null
  }
  const date = dateStr.split('T')[0].split('-')
  const newDate = `${date[2]}/${date[1]}/${date[0]}`
  return newDate
}

export function parseReportDateToShortISO(dateStr: string) {
  const date = dateStr.split('_')
  const newDate = `${date[2]}/${date[1]}/${date[0]}`
  return newDate
}

export function parseMomentToReportDate(date: Date) {
  return date.toISOString().split('T')[0].replaceAll('-', '_')
}

export function parseMomentToMilestoneDate(date: Date) {
  return `${`0${date.getMonth() + 1}`.slice(-2)}/${date.getFullYear()}`
}

export function formatDate(date: string) {
  const d = new Date(date)
  let month = `${d.getMonth() + 1}`
  let day = `${d.getDate()}`
  const year = d.getFullYear()

  if (month.length < 2) month = `0${month}`
  if (day.length < 2) day = `0${day}`

  const h = d.getHours()
  let min = d.getMinutes().toString()
  if (min.length < 2) {
    min = `0${min}`
  }
  const fullDay = [day, month, year].join('/')

  return `${fullDay}, ${h}:${min}`
}

import React, { useEffect, useState } from 'react'
import { Card, Button, Tabs, Row } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { getMilestones } from '../../services/project/milestoneService'
import { getBiologicalSpeciesAll } from '../../services/project/biologicalSpeciesService'
import { getTreeSpeciesAll } from '../../services/project/treeSpeciesService'
import { getProjectTypes } from '../../services/project/projectTypeService'
import { getProjectDevelopers } from '../../services/project/projectDeveloperService'
import { getBenefits } from '../../services/project/benefitService'
import Table from '../../components/Table'
import CreateEditContentModal from './modals/CreateEditContentModal'
import HeaderWithAvatar from '../../components/HeaderAvatar'

const { TabPane } = Tabs

const ContentManagement = () => {
  const [isOpen, setModal] = useState(false)
  const [typeModal, setTypeModal] = useState('')
  const [object, setObject] = useState<any>()
  const [milestones, setMilestones] = useState<any>()
  const [benefits, setBenefits] = useState<any>()
  const [biologicalSpecies, setBiologicalSpecies] = useState<any>()
  const [treeSpecies, setTreeSpecies] = useState<any>()
  const [types, setTypes] = useState<any>()
  const [developers, setDevelopers] = useState<any>()

  const loadMilestones = async () => {
    const array = await getMilestones()
    const newArrayOfObj = array?.map(({
      milestoneTranslations: translations,
      ...rest
    }: any) => ({
      translations,
      ...rest,
    }))
    setMilestones(newArrayOfObj)
  }

  const loadBiologicalSpecies = async () => {
    const array = await getBiologicalSpeciesAll()
    const newArrayOfObj = array?.map(({
      biologicalSpeciesTranslations: translations,
      ...rest
    }: any) => ({
      translations,
      ...rest,
    }))
    setBiologicalSpecies(newArrayOfObj)
  }

  const loadTreeSpecies = async () => {
    const array = await getTreeSpeciesAll()
    const newArrayOfObj = array?.map(({
      treeSpeciesTranslations: translations,
      ...rest
    }: any) => ({
      translations,
      ...rest,
    }))
    setTreeSpecies(newArrayOfObj)
  }

  const loadBenefits = async () => {
    const array = await getBenefits()
    const newArrayOfObj = array?.map(({
      benefitTranslations: translations,
      ...rest
    }: any) => ({
      translations,
      ...rest,
    }))
    setBenefits(newArrayOfObj)
  }

  const loadTypes = async () => {
    const array = await getProjectTypes()
    const newArrayOfObj = array?.map(({
      projectTypeTranslations: translations,
      ...rest
    }: any) => ({
      translations,
      ...rest,
    }))
    setTypes(newArrayOfObj)
  }

  const loadDevelopers = async () => {
    setDevelopers(await getProjectDevelopers())
  }

  const openModal = () => setModal(true)

  const closeModal = () => {
    setModal(false)
    setObject(null)
  }

  useEffect(() => {
    loadMilestones()
    loadBiologicalSpecies()
    loadBenefits()
    loadTreeSpecies()
    loadTypes()
    loadDevelopers()
  }, [])

  useEffect(() => {
    if (!isOpen) {
      if (typeModal === 'milestone') { loadMilestones() }
      if (typeModal === 'biologicalSpecies') loadBiologicalSpecies()
      if (typeModal === 'benefit') loadBenefits()
      if (typeModal === 'treeSpecies') loadTreeSpecies()
      if (typeModal === 'projectType') loadTypes()
      if (typeModal === 'projectDeveloper') loadDevelopers()
    }
  }, [isOpen])

  const milestoneColumns = ['id', 'iconUrl', 'name', 'translations']
  const listColumns = ['id', 'name', 'translations']
  const developerColumns = ['id', 'name', 'email', 'phone', 'address', 'city', 'zipcode', 'country']

  const list = [
    {
      tab: 'Milestones',
      type: 'milestone',
      data: milestones,
      cols: milestoneColumns,
    },
    {
      tab: 'Benefits',
      type: 'benefit',
      data: benefits,
      cols: listColumns,
    },
    {
      tab: 'Tree Species',
      type: 'treeSpecies',
      data: treeSpecies,
      cols: listColumns,
    },
    {
      tab: 'Biological Species',
      type: 'biologicalSpecies',
      data: biologicalSpecies,
      cols: listColumns,
    },
    {
      tab: 'Project Types',
      type: 'projectType',
      data: types,
      cols: listColumns,
    },
    {
      tab: 'Project Developers',
      type: 'projectDeveloper',
      data: developers,
      cols: developerColumns,
    },
  ]

  const List = ({ type, cols, data }: typeof list[number]) => (
    <>
      <Row>
        <Button
          type="primary"
          shape="round"
          icon={<PlusOutlined />}
          onClick={() => {
            setTypeModal(type)
            openModal()
          }}
          className="ml-auto"
        >
          New
        </Button>
      </Row>
      <Table
        data={data}
        cols={cols}
        type="list"
        onClick={(p: typeof data) => {
          setObject(p)
          setTypeModal(type)
          openModal()
        }}
      />
    </>
  )

  return (
    <>
      <HeaderWithAvatar>
        <h3 className="pt-1">
          <b>
            Content Management
          </b>
        </h3>
      </HeaderWithAvatar>
      <Card>
        <Tabs defaultActiveKey="milestone">
          {list.map((l) => (
            <TabPane tab={l.tab} key={l.type}>
              {l.data && <List {...l} />}
            </TabPane>
          ))}
        </Tabs>
        {isOpen && <CreateEditContentModal isOpen={isOpen} setIsOpen={closeModal} type={typeModal} initItem={object} />}
      </Card>
    </>
  )
}

export default ContentManagement

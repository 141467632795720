import React, { useEffect, useState } from 'react'
import { Col, Row, Image, Space, Card, FormInstance } from 'antd'
import { FormOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import UploadList from 'antd/es/upload/UploadList'
import { MapContainer, Marker, TileLayer, ZoomControl } from 'react-leaflet'
import { Map as MapLeaf } from 'leaflet'
import { useAppSelector } from '../../../redux/hooks'
import { getLanguage } from '../../../redux/reducers/projectManagementReducer'
import { getProjectDeveloper } from '../../../services/project/projectDeveloperService'
import { Country, Milestone, ProjectDeveloper, ProjectType } from '../../../types/project'
import { getCountry } from '../../../services/project/countriesService'
import { User } from '../../../types/user'
import { getWember } from '../../../services/wembersService'
import { getProjectType } from '../../../services/project/projectTypeService'
import { ProjectRequest, TextTypes } from '../../../types/service'
import { getMilestones } from '../../../services/project/milestoneService'
import { getApiAssets } from '../../../services'
import { getParcelCenter, renderParcel } from '../../../tools/mapTools'

const ProjectSummary = ({ setCurrent, hasId, form }:
{setCurrent:any, hasId:any, form: FormInstance<ProjectRequest | undefined>}) => {
  const values = form.getFieldsValue(true)
  const [projectDeveloper, setProjectDeveloper] = useState<ProjectDeveloper>()
  const [country, setCountry] = useState<Country>()
  const [owner, setOwner] = useState<User>()
  const [projectType, setProjectType] = useState<ProjectType>()
  const navigate = useNavigate()
  const selectedLanguage = useAppSelector(getLanguage)
  const [milestones, setMilestones] = useState<Milestone[]>([])
  const [map, setMap] = useState<MapLeaf>()
  const [geojson, setGeojson] = useState<any>()
  const [marker, setMarker] = useState<any>()

  const loadMilestones = async () => {
    setMilestones(await getMilestones())
  }

  useEffect(() => {
    loadMilestones()
  }, [])

  useEffect(() => {
    if (values?.markerLocation && !marker) setMarker(JSON.parse(values?.markerLocation))
  }, [values?.markerLocation])

  useEffect(() => {
    if (values?.shape && !geojson) setGeojson(values?.shape)
  }, [values?.shape])

  const steps = [
    {
      title: 'Project details',
      url: 'details',
    },
    {
      title: 'Project overview',
      url: 'overview',
    },
    {
      title: 'Project data',
      url: 'data',
    },
    {
      title: 'Project milestones',
      url: 'milestones',
    },
    {
      title: 'Summary',
      url: 'summary',
    },
  ]

  const getPJ = async () => {
    setProjectDeveloper(await (await getProjectDeveloper(values?.projectDeveloperId || 1)).name)
  }

  const getC = async () => {
    setCountry(await (await getCountry(values?.countryId || 1)).name)
  }

  const getO = async () => {
    setOwner(await (await getWember(values?.ownerId || 1)).name)
  }

  const getT = async () => {
    setProjectType(await (await getProjectType(values?.projectTypeId || values?.types?.[0].projectTypeId || 1)).name)
  }

  useEffect(() => {
    if (values?.projectDeveloperId) { getPJ() }
    if (values?.countryId) { getC() }
    if (values?.ownerId) { getO() }
    if (values?.projectTypeId || values?.types?.[0]) { getT() }
  }, [values])

  useEffect(() => {
    if (geojson) {
      map?.flyTo(getParcelCenter(geojson), 13, { duration: 0.5 })
    }
  }, [geojson])

  const onModify = (i: number) => {
    navigate(`/projects/${steps[i].url}${hasId()}`)
    setCurrent(i)
  }

  const ProjectOverview = () => (
    <Col>
      <b>
        2. Project overview
      </b>
      <FormOutlined className="ml-3" onClick={() => onModify(1)} />
      <Card size="small" className="mt-3">
        <Col style={{ padding: '1rem' }}>
          <Row>
            <Col>
              <div style={{ color: '#b6b6b6' }}>Why we love this project</div>
              {values?.newTexts?.[TextTypes.WHY_WE_LOVE]?.[selectedLanguage.language]}
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <div style={{ color: '#b6b6b6' }}>About the caregivers</div>
              {values?.newTexts?.[TextTypes.CAREGIVERS]?.[selectedLanguage.language]}
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <div style={{ color: '#b6b6b6' }}>Image uploaded</div>
              <Row>
                {values?.images?.map((item: any) => {
                  const newItem = { ...item }
                  return (
                    <UploadList
                      key={item.uid}
                      locale={{ removeFile: 'remove' }}
                      showDownloadIcon={false}
                      showPreviewIcon={false}
                      listType="picture-card"
                      showRemoveIcon={false}
                      items={[newItem]}
                    />
                  )
                })}
              </Row>
            </Col>
          </Row>
        </Col>
      </Card>
    </Col>
  )

  const ProjectData = () => (
    <Col>
      <b>
        3. Project data
      </b>
      <FormOutlined className="ml-3" onClick={() => onModify(2)} />
      <Card size="small" className="mt-3" style={{ padding: '1rem', lineHeight: 2.3 }}>
        <Row>
          <Col sm={6} style={{ color: '#b6b6b6' }}>Biomass</Col>
        </Row>
        <Row>
          <Col sm={6} style={{ color: '#b6b6b6' }}>Price/ton:</Col>
          <Col sm={4}>{values?.price}</Col>
          <Col sm={6} style={{ color: '#b6b6b6' }}>Carbon capacity:</Col>
          <Col>{values?.carbonCapacity}</Col>
        </Row>
        <Row>
          <Col sm={6} style={{ color: '#b6b6b6' }}>Carbon available:</Col>
          <Col sm={4}>{values?.carbonAvailable}</Col>
          <Col sm={6} style={{ color: '#b6b6b6' }}>Saleable carbon:</Col>
          <Col sm={4}>{values?.saleableCarbon}</Col>
        </Row>
        <Row>
          <Col sm={6} style={{ color: '#b6b6b6' }}>Total communities:</Col>
          <Col sm={4}>{values?.totalCommunities}</Col>
          <Col sm={6} style={{ color: '#b6b6b6' }}>People empowered:</Col>
          <Col sm={4}>{values?.peopleEmpowered}</Col>
        </Row>
        <Row>
          <Col sm={6} style={{ color: '#b6b6b6' }}>Nb of trees planted:</Col>
          <Col sm={4}>{values?.nbTreesPlanted}</Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <div style={{ color: '#b6b6b6' }}>Biodiversity</div>
            <div>
              {'• '}
              {values?.newTexts?.[TextTypes.TREE_SPECIES]?.[selectedLanguage.language]}
            </div>
            <div>
              {'• '}
              {values?.newTexts?.[TextTypes.BIOLOGICAL_SPECIES]?.[selectedLanguage.language]}
            </div>
            <div>
              {'• '}
              {values?.newTexts?.[TextTypes.BIODIVERSITY]?.[selectedLanguage.language]}
            </div>
            <div>
              {'• '}
              {values?.newTexts?.[TextTypes.LIVELIHOOD]?.[selectedLanguage.language]}
            </div>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <div style={{ color: '#b6b6b6' }}>Benefits</div>
            {values?.newTexts?.[TextTypes.BENEFIT]?.[selectedLanguage.language]}
          </Col>
        </Row>
      </Card>

    </Col>
  )

  const ProjectMilestones = () => (
    <Col>
      <b>
        4. Project milestones
      </b>
      <FormOutlined className="ml-3" onClick={() => onModify(3)} />

      <Card size="small" className="mt-3" style={{ padding: '1rem', lineHeight: 2.3 }}>
        <div style={{ color: '#b6b6b6' }}>Milestones</div>
        <Space direction="vertical" className="mt-2">
          {values?.milestones?.map((milestone: Milestone) => {
            const found = milestones.find((m) => m.id === milestone.milestoneId)
            return (
              <Space direction="horizontal" key={milestone.milestoneId + milestone.date} className="mb-3">
                <Image
                  width={32}
                  preview={false}
                  src={getApiAssets(found?.iconUrl)}
                />
                <div className="ml-1">
                  {found?.name}
                  {' - '}
                  {milestone.date}
                </div>
              </Space>
            )
          })}
        </Space>
      </Card>
    </Col>
  )

  return (
    <div>
      <Row style={{ width: '100%', justifyContent: 'space-around' }} gutter={16}>
        <Col flex="auto" className="mt-3 mb-3">
          <b>
            1. Project details
          </b>
          <FormOutlined className="ml-3" onClick={() => onModify(0)} />
          <Card size="small" className="mt-3" style={{ padding: '1rem', lineHeight: 2.3 }}>
            <Row>
              <Col span={7} style={{ color: '#b6b6b6' }}>Project name</Col>
              <Col>{values?.name}</Col>
            </Row>
            <Row>
              <Col span={7} style={{ color: '#b6b6b6' }}>Project developer</Col>
              <Col>{projectDeveloper}</Col>
            </Row>
            <Row>
              <Col span={7} style={{ color: '#b6b6b6' }}>Project area:</Col>
              <Col span={5} style={{ color: '#b6b6b6' }}>Country:</Col>
              <Col>{country}</Col>
            </Row>
            <Row>
              <Col flex="auto" offset={7}>
                <Row style={{
                  width: '100%',
                  height: 200,
                  border: '1px solid #9e9e9e',
                  borderRadius: 4,
                  justifyContent: 'center',
                }}
                >
                  {geojson ? (
                    <MapContainer
                      zoom={13}
                      center={getParcelCenter(geojson)}
                      zoomControl={false}
                      whenCreated={setMap}
                    >
                      <TileLayer
                        url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                      />
                      {renderParcel(geojson)}
                      {marker && <Marker position={marker} />}
                      <ZoomControl position="topright" />
                    </MapContainer>
                  ) : (
                    <div
                      style={{
                        alignSelf: 'center',
                        color: '#9e9e9e',
                      }}
                    >
                      No Shapefile found
                    </div>
                  )}
                </Row>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col span={7} style={{ color: '#b6b6b6' }}>Project type</Col>
              <Col>{projectType}</Col>
            </Row>
            <Row>
              <Col span={7} style={{ color: '#b6b6b6' }}>Account owner</Col>
              <Col>{owner}</Col>
            </Row>
          </Card>
        </Col>
        <Col flex="auto" className="mt-3 mb-3" style={{ overflowWrap: 'break-word' }}>
          <ProjectData />
        </Col>
      </Row>
      <Row style={{ width: '100%', justifyContent: 'space-around' }} gutter={16}>
        <Col flex="auto" md={12} className="mt-3 mb-3" style={{ overflowWrap: 'break-word' }}>
          <ProjectOverview />
        </Col>
        <Col flex="auto" className="mt-3 mb-3">
          <ProjectMilestones />
        </Col>
      </Row>
    </div>
  )
}

export default ProjectSummary

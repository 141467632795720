import { User } from './user'

export const languages: Language[] = [
  { name: 'Francais', language: 'fr' },
  { name: 'Italiano', language: 'it' },
  { name: 'Español', language: 'es' },
]

export interface Language {
  name: string,
  language: string
}

export interface ProjectDeveloper {
  id: number
  name: string
  email: string
  phone: string
  address: string
  city: string
  zipcode: string
  country: string
}

export interface CountryTranslation {
  id: number
  countryId: number
  country: Country
  name: string
  language: string
  createdAt: Date
  updatedAt: Date
}

export interface Country {
  id: number
  name: string
  countryTranslations: CountryTranslation[]
  createdAt: Date
  updatedAt: Date
}

export type ProjectTextTypes = {
  WHY_WE_LOVE: 'whyWeLove',
  CAREGIVERS: 'caregivers',
  TREE_SPECIES: 'treeSpecies',
  BIOLOGICAL_SPECIES: 'biologicalSpecies',
  BENEFIT: 'benefit',
  BIODIVERSITY: 'biodiversity',
  LIVELIHOOD: 'livelihood'
}

export interface ProjectText {
  id: number
  projectId: number
  type: keyof ProjectTextTypes
  language: string
  text: string
  createdAt: Date
  updatedAt: Date
}

export interface ProjectTypeTranslation {
  id: number
  projectTypeId: number
  projectType: ProjectType
  name: string
  language: string
  createdAt: Date
  updatedAt: Date
}

export interface ProjectType {
  id: number
  name: string
  projectTypeTranslations: ProjectTypeTranslation[]
  createdAt: Date
  updatedAt: Date
}

export interface ProjectProjectType {
  id: number
  projectTypeId: number
  projectType: ProjectType
  projectTypeTranslations: ProjectTypeTranslation[]
  projectId: number
  project: Project
  createdAt: Date
  updatedAt: Date
}

export interface BiologicalSpeciesTranslation {
  id: number
  biologicalSpeciesId: number
  biologicalSpecies: BiologicalSpecies
  name: string
  language: string
  createdAt: Date
  updatedAt: Date
}

export interface BiologicalSpecies {
  id: number
  name: string
  biologicalSpeciesTranslations: BiologicalSpeciesTranslation[]
  createdAt: Date
  updatedAt: Date
}

export interface ProjectBiologicalSpecies {
  id: number
  biologicalSpeciesId: number
  biologicalSpecies: BiologicalSpecies
  biologicalSpeciesTranslations: BiologicalSpeciesTranslation[]
  projectId: number
  project: Project
  createdAt: Date
  updatedAt: Date
}

export interface TreeSpeciesTranslations {
  id: number
  treeSpeciesId: number
  treeSpecies: TreeSpecies
  name: string
  language: string
  createdAt: Date
  updatedAt: Date
}

export interface TreeSpecies {
  id: number
  name: string
  treeSpeciesTranslations: TreeSpeciesTranslations[]
  createdAt: Date
  updatedAt: Date
}

export interface ProjectTreeSpecies {
  id: number
  treeSpeciesId: number
  treeSpecies: TreeSpecies
  treeSpeciesTranslations: TreeSpeciesTranslations[]
  projectId: number
  project: Project
  createdAt: Date
  updatedAt: Date
}

export interface BenefitTranslation {
  id: number
  benefitId: number
  benefit: Benefit
  name: string
  language: string
  createdAt: Date
  updatedAt: Date
}

export interface Benefit {
  id: number
  name: string
  benefitTranslations: BenefitTranslation[]
  createdAt: Date
  updatedAt: Date
}

export interface ProjectBenefit {
  id: number
  benefitId: number
  benefit: Benefit
  benefitTranslations: BenefitTranslation[]
  projectId: number
  project: Project
  createdAt: Date
  updatedAt: Date
}

export interface MilestoneTranslation {
  id: number
  milestoneId: number
  milestone: Milestone
  name: string
  language: string
  createdAt: Date
  updatedAt: Date
}

export interface Milestone {
  id: number
  name: string
  iconUrl: string
  date: string
  milestoneId: number
  milestoneTranslations: MilestoneTranslation[]
  createdAt: Date
  updatedAt: Date
}

export interface ProjectMilestone {
  id: number
  milestoneId: number
  milestoneTranslations: MilestoneTranslation[]
  projectId: string
  files: File[]
  date: string
  createdAt: Date
  updatedAt: Date
}

export interface Project {
  id: number
  name: string
  projectDeveloperId: number
  projectDeveloper: ProjectDeveloper
  countryId: number
  country: Country
  markerLocation: string
  shape: JSON
  text: ProjectText[]
  imagesUrl: string
  price: number
  carbonCapacity: number
  carbonAvailable: number
  types: ProjectProjectType[]
  biologicalSpecies: ProjectBiologicalSpecies[]
  treeSpecies: ProjectTreeSpecies[]
  benefits: ProjectBenefit[]
  milestones: ProjectMilestone[]
  visible: boolean
  archived: boolean
  submitted: boolean
  ownerId: number
  owner: User
  lastEditedById: number
  lastEditedBy: User
  createdAt: Date
  updatedAt: Date

  // ignore
  images: {fileList: any[]} | undefined
}

import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Card, Col, DatePicker, Divider, Form, Input, InputNumber, Row, Select } from 'antd'
import moment from 'moment'
import { useAppSelector } from '../../../redux/hooks'
import { getLanguage } from '../../../redux/reducers/projectManagementReducer'
import HeaderWithAvatar from '../../../components/HeaderAvatar'
import { TransactionRequest } from '../../../types/service'
import api from '../../../services/index'
import { createTransaction, getTransaction, updateTransaction } from '../../../services/project/transactionService'

const { TextArea } = Input
const { Option } = Select

const NewTransactionPage = ({ edit = false }: {edit?: boolean}) => {
  const selectedLanguage = useAppSelector(getLanguage)
  const [validated, setValidated] = useState(false)
  const id = parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 1], 10)
  const [form] = Form.useForm<TransactionRequest | undefined>()
  const hasId = () => (edit ? `/${id}` : '')

  const onFinish = async (values: any) => {
    if (!edit) {
      await createTransaction(values)
    } else {
      await updateTransaction(values, id)
    }
  }

  const loadTransaction = async () => {
    const data = await getTransaction(id)
    data.remainingOn = moment(data.remainingOn, 'YYYY-MM-DD')
    data.retiredOn = moment(data.retiredOn, 'YYYY-MM-DD')
    form.setFieldsValue(data)
  }

  useEffect(() => {
    if (edit) {
      loadTransaction()
    }
  })

  return (
    <>
      <HeaderWithAvatar>
        <h3 className="pt-1">
          <b>
            Restoration /
          </b>
          {' '}
          Transaction details
        </h3>
      </HeaderWithAvatar>
      <Card>
        <Form name="transaction" layout="vertical" onFinish={onFinish} form={form}>
          <Row>
            <b style={{ color: '#41693D' }} className="font-bold tracking-wide">
              Transaction details
            </b>
          </Row>
          <Divider />
          <Row style={{ placeContent: 'space-between' }}>
            <Col span={24}>
              <Form.Item label="Transaction ID" name="transactionId" required>
                <TextArea placeholder="XXXXXXXXXXXX-X" maxLength={14} allowClear autoSize required />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Project Name" name="projectName" required>
                <TextArea placeholder="Insert Name" maxLength={255} allowClear autoSize required />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item label="Carbon allocated" name="carbonAllocated">
                <InputNumber placeholder="Insert number" min={1} addonAfter="tC02" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Remaining carbon" name="remainingCarbon">
                <InputNumber placeholder="Insert number" addonAfter="tC02" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Issued to" name="issuedTo">
                <TextArea placeholder="Insert name" maxLength={255} allowClear autoSize />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Remaining on" name="remainingOn">
                <DatePicker placeholder="DD/MM/YYYY" format="DD/MM/YYYY" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Retired by" name="retiredBy">
                <TextArea placeholder="Insert name" maxLength={255} allowClear autoSize />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Retired on" name="retiredOn">
                <DatePicker placeholder="DD/MM/YYYY" format="DD/MM/YYYY" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Status" name="status" required>
                <Select className="w-full" placeholder="Select a status">
                  <Option value="reserved">Reserved</Option>
                  <Option value="issued">Issued</Option>
                  <Option value="retired">Retired</Option>
                  <Option value="canceled">Canceled</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <br />
          <Row justify="end">
            <Button type="default" shape="round" onClick={undefined} loading={undefined} className="mr-4">Save</Button>
            <Button type="primary" shape="round" htmlType="submit">Submit</Button>
          </Row>
        </Form>
        <br />
        <Row justify="end">
          {validated && <div className="text-green-500">Transaction created successfully!</div>}
        </Row>
      </Card>
    </>
  )
}

NewTransactionPage.defaultProps = { edit: false }

export default NewTransactionPage

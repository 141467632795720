import React, { useEffect, useState } from 'react'
import { Avatar, Card, Col, Image, Row } from 'antd'
import { useAppSelector } from '../redux/hooks'
import { getUser } from '../redux/reducers/userReducer'

const HeaderWithAvatar = ({ children }: {children: any}) => {
  const [randomColor, setRandomColor] = useState(`#${Math.floor(Math.random() * 16777215).toString(16)}`)
  const user = useAppSelector(getUser)

  useEffect(() => {
    setRandomColor(`#${Math.floor(Math.random() * 16777215).toString(16)}`)
  }, [])

  const getAvatarName = () => {
    if (!user?.name) return ''
    const names = user.name.split(' ')
    let initials = names[0].substring(0, 1).toUpperCase()
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase()
    }
    return initials
  }

  return (
    <Card size="small" className="mb-3">
      <Row align="middle" className="px-6">
        <Col>
          {children}
        </Col>
        <Col style={{ marginLeft: 'auto' }}>
          <Avatar
            icon={user?.avatarUrl ? <Image src={user?.avatarUrl} /> : null}
            style={{ backgroundColor: randomColor }}
            size="large"
          >
            {getAvatarName()}
          </Avatar>
        </Col>
      </Row>
    </Card>
  )
}

export default HeaderWithAvatar

import { Request, TranslationRequest } from '../../types/service'
import api from '../index'

export const getTreeSpeciesAll = async () => {
  try {
    const response = await api.get('/treeSpecies')
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const createTreeSpecies = async (request: Request) => {
  try {
    const response = await api.post('/treeSpecies', request)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getTreeSpecies = async (id: number) => {
  try {
    const response = await api.get(`/treeSpecies/${id}`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const updateTreeSpecies = async (request: Request, id: number) => {
  try {
    const response = await api.patch(`/treeSpecies/${id}`, request)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getTreeSpeciesTranslations = async (treeSpeciesId: number) => {
  try {
    const response = await api.get(`/treeSpecies/${treeSpeciesId}/treeSpeciesTranslations`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const createTreeSpeciesTranslation = async (
  treeSpeciesId: number,
  request: TranslationRequest,
) => {
  try {
    const response = await api.post(`/treeSpecies/${treeSpeciesId}/treeSpeciesTranslations`, request)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getTreeSpeciesTranslation = async (treeSpeciesId: number, id: number) => {
  try {
    const response = await api.get(`/treeSpecies/${treeSpeciesId}/treeSpeciesTranslations/${id}`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const updateTreeSpeciesTranslation = async (
  treeSpeciesId: number,
  id: number,
  request: TranslationRequest,
) => {
  try {
    const response = await
    api.patch(`/treeSpecies/${treeSpeciesId}/treeSpeciesTranslations/${id}`, request)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const deleteTreeSpeciesTranslation = async (treeSpeciesId: number, id: number) => {
  try {
    const response = await api.delete(`/treeSpecies/${treeSpeciesId}/treeSpeciesTranslations/${id}`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getProjectTreeSpeciesAll = async (projectId: number, treeSpeciesId: number) => {
  try {
    const response = await api.get(`/projects/${projectId}/treeSpecies/${treeSpeciesId}`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const createProjectTreeSpecies = async (projectId: number, treeSpeciesId: number) => {
  try {
    const response = await api.post(`/projects/${projectId}/treeSpecies/${treeSpeciesId}`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getProjectTreeSpecies = async (projectId: number, treeSpeciesId: number, id: number) => {
  try {
    const response = await api.get(`/projects/${projectId}/treeSpecies/${treeSpeciesId}/${id}`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const updateProjectTreeSpecies = async (projectId: number, treeSpeciesId: number, id: number) => {
  try {
    const response = await api.patch(`/projects/${projectId}/treeSpecies/${treeSpeciesId}/${id}`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const deleteProjectTreeSpecies = async (projectId: number, treeSpeciesId: number, id: number) => {
  try {
    const response = await api.delete(`/projects/${projectId}/treeSpecies/${treeSpeciesId}/${id}`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import { getUploadUrl, getUploadHeader } from '../services';

const FileUploader = ({ ...props }) => {
  return (
      <Upload
        {...props}
        showUploadList={true}
        action={getUploadUrl()}
        headers={getUploadHeader()}
        openFileDialogOnClick={true}
        id="uploader"
        maxCount={1}
      >
        <Button icon={<UploadOutlined />}>Upload PDF</Button>
      </Upload>
  )
}

export default FileUploader;
import { Request, TranslationRequest } from '../../types/service'
import api from '../index'

export const getProjectTypes = async () => {
  try {
    const response = await api.get('/projectTypes')
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const createProjectType = async (request: Request) => {
  try {
    const response = await api.post('/projectTypes', request)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getProjectType = async (id: number) => {
  try {
    const response = await api.get(`/projectTypes/${id}`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const updateProjectType = async (request: Request, id: number) => {
  try {
    const response = await api.patch(`/projectTypes/${id}`, request)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getProjectTypeTranslations = async (projectTypeId: number) => {
  try {
    const response = await api.get(`/projectTypes/${projectTypeId}/projectTypeTranslations`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const createProjectTypeTranslation = async (projectTypeId: number, request: TranslationRequest) => {
  try {
    const response = await api.post(`/projectTypes/${projectTypeId}/projectTypeTranslations`, request)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getProjectTypeTranslation = async (projectTypeId: number, id: number) => {
  try {
    const response = await api.get(`/projectTypes/${projectTypeId}/projectTypeTranslations/${id}`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const updateProjectTypeTranslation = async (projectTypeId: number, id: number, request: TranslationRequest) => {
  try {
    const response = await api.patch(`/projectTypes/${projectTypeId}/projectTypeTranslations/${id}`, request)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const deleteProjectTypeTranslation = async (projectTypeId: number, id: number) => {
  try {
    const response = await api.delete(`/projectTypes/${projectTypeId}/projectTypeTranslations/${id}`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getProjectProjectTypes = async (projectId: number, projectTypeId: number) => {
  try {
    const response = await api.get(`/projects/${projectId}/projectTypes/${projectTypeId}`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const createProjectProjectType = async (projectId: number, projectTypeId: number) => {
  try {
    const response = await api.post(`/projects/${projectId}/projectTypes/${projectTypeId}`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getProjectProjectType = async (projectId: number, projectTypeId: number, id: number) => {
  try {
    const response = await api.get(`/projects/${projectId}/projectTypes/${projectTypeId}/${id}`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const updateProjectProjectType = async (projectId: number, projectTypeId: number, id: number) => {
  try {
    const response = await api.patch(`/projects/${projectId}/projectTypes/${projectTypeId}/${id}`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import * as languages from './languages'

const getMonthEn = (date: Date) => {
  switch (date.getMonth()) {
    case 0:
      return 'January'
    case 1:
      return 'February'
    case 2:
      return 'March'
    case 3:
      return 'April'
    case 4:
      return 'May'
    case 5:
      return 'June'
    case 6:
      return 'July'
    case 7:
      return 'August'
    case 8:
      return 'Septempber'
    case 9:
      return 'October'
    case 10:
      return 'November'
    default:
      return 'December'
  }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next).init({
    interpolation: {
      format(value, format, lang) {
        if (format === 'dateFormat' && value instanceof Date) {
          if (lang === 'en') {
            return `${getMonthEn(
              value,
            )} ${value.getDate()}, ${value.getFullYear()}`
          }
          if (lang === 'fr') {
            const ye = value.getFullYear()
            const mo = (value.getMonth() + 1).toString().padStart(2, '0')
            const da = value.getDate().toString().padStart(2, '0')
            return `${ye}-${mo}-${da}`
          }
        }
        return value
      },
    },
    resources: {
      ...Object.entries(languages).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: { translation: value },
        }),
        {},
      ),
    },
    fallbackLng: 'en',
  })

export default i18n

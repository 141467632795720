import axios from 'axios'
import { logOut } from '../redux/reducers/userReducer'
import { store } from '../redux/store'

const API_URL =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_API_URL
    : `https://api.${window.location.hostname}/`

const instance = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 30000,
})

function isExpired(expiresAt) {
  return new Date() >= new Date(expiresAt)
}

export function isValidToken(token, expiresAt) {
  try {
    if (!token || !expiresAt) {
      return false
    }
    return !isExpired(expiresAt)
  } catch (err) {
    console.error(err)
    return false
  }
}

export function checkAndGetToken() {
  try {
    const { token, tokenExpiresAt } = store.getState().user
    if (!isValidToken(token, tokenExpiresAt)) {
      store.dispatch(logOut())
      return null
    }
    return token
  } catch (err) {
    console.error(err)
    return null
  }
}

function isOpenEndpoint(url, method) {
  return (
    (url === '/login' && method === 'post') ||
    (url === '/register' && method === 'post') ||
    (url === '/password/forget' && method === 'post') ||
    (url === '/contact' && method === 'post')
  )
}

instance.interceptors.request.use((config) => {
  if (!isOpenEndpoint(config.url, config.method)) {
    config.headers.common.Authorization = `Bearer ${checkAndGetToken()}`
  }
  return config
})

export function getUploadUrl() {
  return `${API_URL}/upload`
}

export function getUploadHeader() {
  return { Authorization: `Bearer ${checkAndGetToken()}` }
}

export default instance

export const apiFormData = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data',
  },
  timeout: 30000,
})

apiFormData.interceptors.request.use((config) => {
  if (!isOpenEndpoint(config.url, config.method)) {
    config.headers.common.Authorization = `Bearer ${checkAndGetToken()}`
  }
  return config
})

export const getApiAssets = (url) => API_URL + url

/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ProjectRequest } from '../../types/service'
import api, { apiFormData } from '../index'

export const getProjects = async () => {
  const response = await api.get('/projects')
  return response.data
}

export const getArchivedProjects = async () => {
  const response = await api.get('/projects/archived')
  return response.data
}

export const getProject = async (id: number) => {
  const response = await api.get(`/projects/${id}`)
  return response.data
}

export const createProject = async (request: ProjectRequest) => {
  const formData = new FormData()
  Object.keys(request).forEach((key) => {
    // @ts-ignore
    if (request[key] !== 'undefined' && request[key] !== null && request[key] !== undefined) {
    // @ts-ignore
      if (Array.isArray(request[key])) {
        // @ts-ignore
        request[key].forEach((item: File) => formData.append(`${key}[]`, item))
      } else {
        // @ts-ignore
        formData.append(key, request[key])
      }
    }
  })
  const response = await apiFormData.post('/projects', formData)
  return response.data
}

export const updateProject = async (request: ProjectRequest, id: number) => {
  const formData = new FormData()
  Object.keys(request).forEach((key) => {
    // @ts-ignore
    if (request[key] !== 'undefined' && request[key] !== null && request[key] !== undefined) {
    // @ts-ignore
      if (Array.isArray(request[key])) {
        // @ts-ignore
        request[key].forEach((item: File) => formData.append(`${key}[]`, item))
      } else {
        // @ts-ignore
        formData.append(key, request[key])
      }
    }
  })
  const response = await apiFormData.patch(`/projects/${id}`, formData)
  return response.data
}

import React, { useEffect, useState } from 'react'
import { Input, Form, Button, Select, FormInstance, Row, Col } from 'antd'
import { MinusCircleOutlined, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { getBenefits } from '../../../services/project/benefitService'
import { getTreeSpeciesAll } from '../../../services/project/treeSpeciesService'
import { getBiologicalSpeciesAll } from '../../../services/project/biologicalSpeciesService'
import { useAppSelector } from '../../../redux/hooks'
import { getLanguage } from '../../../redux/reducers/projectManagementReducer'
import { ProjectRequest, TextTypes } from '../../../types/service'
import CreateEditContentModal from '../modals/CreateEditContentModal'

const { TextArea } = Input

const InputTab = (props: {name : string, type: string, reload?: boolean}) => {
  const [options, setOptions] = useState<any>([])

  const loadBenefits = async () => {
    setOptions(await getBenefits())
  }
  const loadTreeSpeciesAll = async () => {
    setOptions(await getTreeSpeciesAll())
  }
  const loadBiologicalSpeciesAll = async () => {
    setOptions(await getBiologicalSpeciesAll())
  }

  useEffect(() => {
    if (props.reload) {
      if (props.type === 'benefits') loadBenefits()
      else if (props.type === 'tree species') loadTreeSpeciesAll()
      else loadBiologicalSpeciesAll()
    }
  }, [props.reload])

  return (
    <Form.List name={props.name}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <Row key={key} style={{ display: 'flex', marginBottom: 8, width: '100%' }}>
              <Col span={22}>
                <Form.Item
                  {...restField}
                  name={name}
                >
                  <Select
                    placeholder={`Insert ${props.type}`}
                    showSearch
                    filterOption={(input, option) => {
                      if (!option?.children) return true
                      return option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}
                  >
                    {options.map((option: any) => (<Select.Option key={option.id}>{option.name}</Select.Option>))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2} style={{ textAlign: 'right', paddingTop: 6 }}>
                <MinusCircleOutlined onClick={() => remove(name)} style={{ fontSize: 20 }} />
              </Col>
            </Row>
          ))}
          <Form.Item>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              {`Add ${props.type}`}
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  )
}

InputTab.defaultProps = { reload: false }

const ProjectData = ({ form }: {form: FormInstance<ProjectRequest | undefined>}) => {
  const selectedLanguage = useAppSelector(getLanguage)
  const [isOpen, setModal] = useState(false)
  const [type, setType] = useState('')

  const openModal = (t: string) => {
    setType(t)
    setModal(true)
  }

  const closeModal = () => {
    setModal(false)
  }

  return (
    <Form.Item>
      {isOpen && <CreateEditContentModal isOpen={isOpen} setIsOpen={closeModal} type={type} />}
      <Form.Item label="Biomass" wrapperCol={{ offset: 1 }}>
        <Row style={{ placeContent: 'space-between' }}>
          <Col span={10}>
            <Form.Item label="Price/ton " name="price">
              <Input
                suffix="$"
                type="number"
              />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item label="Carbon capacity " name="carbonCapacity">
              <Input
                suffix="tC02"
                type="number"
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item label="Number of planted trees" name="nbTreesPlanted">
              <Input
                type="number"
              />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item label="Ton of saleable carbon" name="saleableCarbon">
              <Input
                suffix="t"
                type="number"
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item label="Total communities" name="totalCommunities">
              <Input
                type="number"
              />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item label="People empowered" name="peopleEmpowered">
              <Input
                type="number"
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item label="Carbon available" name="carbonAvailable">
              <Input
                suffix="t"
                type="number"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>

      <Form.Item label="Biodiversity" wrapperCol={{ offset: 1 }}>
        <Row style={{ placeContent: 'space-between' }}>
          <Col span={10}>
            <div className="title-button">
              <div>Biological species</div>
              <PlusCircleOutlined onClick={() => openModal('biologicalSpecies')} style={{ fontSize: 18 }} />
            </div>
            <Form.Item>
              <InputTab name="biologicalSpecies" type="biological species" reload={!isOpen} />
            </Form.Item>
          </Col>
          <Col span={11}>
            <div className="title-button">
              <div>Tree species</div>
              <PlusCircleOutlined onClick={() => openModal('treeSpecies')} style={{ fontSize: 18 }} />
            </div>
            <Form.Item>
              <InputTab name="treeSpecies" type="tree species" reload={!isOpen} />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ placeContent: 'space-between' }}>
          <Col span={10}>
            <Form.Item
              label="About the biological species "
              name={['newTexts', TextTypes.BIOLOGICAL_SPECIES, selectedLanguage.language]}
              extra={selectedLanguage.language !== 'en'
                ? form.getFieldValue('newTexts')?.[TextTypes.BIOLOGICAL_SPECIES]?.en : ''}
            >
              <TextArea
                rows={4}
                allowClear
                disabled={selectedLanguage.language !== 'en'
            && !form.getFieldValue('newTexts')?.[TextTypes.BIOLOGICAL_SPECIES]?.en}
              />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label="About the tree species "
              name={['newTexts', TextTypes.TREE_SPECIES, selectedLanguage.language]}
              extra={selectedLanguage.language !== 'en'
                ? form.getFieldValue('newTexts')?.[TextTypes.TREE_SPECIES]?.en : ''}
            >
              <TextArea
                rows={4}
                allowClear
                disabled={selectedLanguage.language !== 'en'
            && !form.getFieldValue('newTexts')?.[TextTypes.TREE_SPECIES]?.en}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ placeContent: 'space-between' }}>
          <Col span={10}>
            <Form.Item
              label="About the biodiversity"
              name={['newTexts', TextTypes.BIODIVERSITY, selectedLanguage.language]}
              extra={selectedLanguage.language !== 'en'
                ? form.getFieldValue('newTexts')?.[TextTypes.BIODIVERSITY]?.en : ''}
            >
              <TextArea
                rows={4}
                allowClear
                disabled={selectedLanguage.language !== 'en'
                && !form.getFieldValue('newTexts')?.[TextTypes.BIODIVERSITY]?.en}
              />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label="About the livelihood"
              name={['newTexts', TextTypes.LIVELIHOOD, selectedLanguage.language]}
              extra={selectedLanguage.language !== 'en'
                ? form.getFieldValue('newTexts')?.[TextTypes.LIVELIHOOD]?.en : ''}
            >
              <TextArea
                rows={4}
                allowClear
                disabled={selectedLanguage.language !== 'en'
                && !form.getFieldValue('newTexts')?.[TextTypes.LIVELIHOOD]?.en}
              />
            </Form.Item>
          </Col>
        </Row>

      </Form.Item>
      <div className="title-button">
        <div>Benefits</div>
        <PlusCircleOutlined onClick={() => openModal('benefit')} style={{ fontSize: 18 }} />
      </div>
      <Form.Item>
        <InputTab name="benefits" type="benefits" reload={!isOpen} />
      </Form.Item>
      <Form.Item
        label="About the benefits"
        name={['newTexts', TextTypes.BENEFIT, selectedLanguage.language]}
        extra={selectedLanguage.language !== 'en'
          ? form.getFieldValue('newTexts')?.[TextTypes.BENEFIT]?.en : ''}
      >
        <TextArea
          rows={4}
          allowClear
          disabled={selectedLanguage.language !== 'en'
          && !form.getFieldValue('newTexts')?.[TextTypes.BENEFIT]?.en}
        />
      </Form.Item>
    </Form.Item>
  )
}

export default ProjectData

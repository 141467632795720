import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Input, Row, Form, Select } from 'antd'
import { UploadFile } from 'antd/lib/upload/interface'
import { useTranslation } from 'react-i18next'
import { LeftOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { User, languages } from '../../types/user'
import { getWember, updateWember, updateAvatar, deleteAvatar } from '../../services/wembersService'
import AvatarUpload from '../../components/AvatarUpload'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { getUser, setUser } from '../../redux/reducers/userReducer'
import HeaderWithAvatar from '../../components/HeaderAvatar'

const SettingsPage = () => {
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [avatar, setAvatar] = useState<UploadFile[]>([])
  const [wember, setWember] = useState<User>()
  const { t } = useTranslation('', { keyPrefix: 'settings' })
  const user = useAppSelector(getUser)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const loadWember = async () => {
    const newW = await getWember(user!.id)
    setWember(newW)
    dispatch(setUser(newW))
    if (newW.avatarUrl) {
      setAvatar([{
        uid: newW.avatarUrl + Math.random(),
        name: newW.avatarUrl,
        thumbUrl: newW.avatarUrl,
      }])
    }
  }

  useEffect(() => {
    loadWember()
  }, [])

  const editWember = async (values: any) => {
    try {
      if (wember) {
        await updateWember({ ...values, avatar: avatar[0]?.originFileObj }, wember.id)
        if (avatar[0]?.originFileObj) { await updateAvatar({ avatar: avatar[0]?.originFileObj }, wember.id) }
        await loadWember()
        setSuccess('Settings saved successfully')
      }
    } catch (e) {
      setError('Error')
    }
  }

  const removeAvatar = async () => {
    try {
      await deleteAvatar(wember!.id)
      setWember({ ...wember!, avatarUrl: undefined })
      await loadWember()
    } catch (e) {
      setError('Error')
    }
  }

  const getDays = () => {
    const days = []
    for (let i = 0; i < 7; i += 1) {
      days.push({ value: i, label: t(`day${i}`) })
    }
    return days
  }

  const getHours = () => {
    const hours = []
    for (let i = 0; i < 24; i += 1) {
      hours.push({ value: i, label: `${i + 1}h` })
    }
    return hours
  }

  return (
    <>
      <HeaderWithAvatar>
        <div style={{ display: 'flex' }}>
          <Button
            icon={<LeftOutlined />}
            shape="circle"
            onClick={() => navigate('/projects')}
            className="mr-3"
            type="link"
          />
          <h3 className="pt-1">
            <b>
              Settings
            </b>
          </h3>
        </div>
      </HeaderWithAvatar>
      <Card
        title={(
          <div style={{ display: 'flex' }}>
            <h2>
              Settings
            </h2>
          </div>
        )}
      >
        {wember && (
          <Form
            name="editWember"
            initialValues={{ ...wember }}
            onFinish={editWember}
            layout="vertical"
            onValuesChange={() => {
              setError('')
              setSuccess('')
            }}
          >
            <Row>
              <Col className="mr-11">
                <AvatarUpload files={avatar} setFileList={setAvatar} onRemove={removeAvatar} />
              </Col>
              <Col flex="auto">
                <Row>
                  <Col flex="auto">
                    <Form.Item
                      label={t('firstname')}
                      name="firstname"
                      className="pr-3"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Jean-Edouard" />
                    </Form.Item>
                  </Col>
                  <Col flex="auto" className="pl-3">
                    <Form.Item
                      label={t('lastname')}
                      name="lastname"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Wember" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row style={{ placeContent: 'space-between' }}>
              <Col span={11}>
                <Form.Item
                  label={t('email')}
                  name="email"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="hello@wildsense.co" />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item
                  label={t('password')}
                  name="password"
                >
                  <Input.Password />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ placeContent: 'space-between' }}>
              <Col span={11}>
                <Form.Item
                  label={t('phone')}
                  name="phone"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="0605040302" />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item
                  label={t('language')}
                  name="language"
                  rules={[{ required: true }]}
                >
                  <Select options={languages} />
                </Form.Item>
              </Col>
            </Row>

            <Row style={{ placeContent: 'space-between' }}>
              <Col span={6}>
                <Form.Item
                  label={t('dayFrom')}
                  name={['availability', 'dayFrom']}
                  rules={[{ required: true }]}
                >
                  <Select options={getDays()} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label={t('dayTo')}
                  name={['availability', 'dayTo']}
                  rules={[{ required: true }]}
                >
                  <Select options={getDays()} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label={t('hourFrom')}
                  name={['availability', 'hourFrom']}
                  rules={[{ required: true }]}
                >
                  <Select options={getHours()} />
                </Form.Item>
              </Col>

              <Col span={4}>
                <Form.Item
                  label={t('hourTo')}
                  name={['availability', 'hourTo']}
                  rules={[{ required: true }]}
                >
                  <Select options={getHours()} />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item style={{ textAlign: 'end' }}>
              <Button type="primary" htmlType="submit">
                {wember ? t('editWember') : t('createWember')}
              </Button>
            </Form.Item>
            <Row>
              <p className="ml-auto mr-1 error">
                {error}
              </p>
            </Row>
            <Row>
              <p className="ml-auto mr-1 success">
                {success}
              </p>
            </Row>
          </Form>
        )}
      </Card>
    </>

  )
}

export default SettingsPage

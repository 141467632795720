import React, { useState } from 'react'
import { Radio, Dropdown, Menu, Button, Tooltip } from 'antd'
import { DeleteFilled, PlusOutlined } from '@ant-design/icons'
import { useAppDispatch, useAppSelector } from '../redux/hooks'
import {
  getLanguage,
  getLanguages,
  setLanguages,
  setLanguage,
  resetLanguage,
} from '../redux/reducers/projectManagementReducer'
import { languages } from '../types/project'

const HandleLanguage = () => {
  const dispatch = useAppDispatch()
  const selectedLanguages = useAppSelector(getLanguages)
  const selectedLanguage = useAppSelector(getLanguage)
  const [availableLanguages, setAvailableLanguages] = useState(
    languages
      .filter((l) => !selectedLanguages
        .find((sl) => sl.language === l.language)),
  )
  const onChange = (e: any) => {
    const found = languages.find((l) => l.language === e.target.value)
    if (found) dispatch(setLanguage(found))
    else dispatch(resetLanguage())
  }

  const handleRemove = (key: string) => {
    const found = languages.find((l) => l.language === key)
    if (!found) return
    const filtered = selectedLanguages.filter((l) => l.language !== key)
    dispatch(setLanguages(filtered))
    setAvailableLanguages([...availableLanguages, found])
  }

  const handleMenuClick = ({ key }: {key: React.Key}) => {
    const found = languages.find((l) => l.language === key)
    if (found) dispatch(setLanguages([...selectedLanguages, found]))
    const filtered = availableLanguages.filter((l) => l.language !== key)
    setAvailableLanguages(filtered)
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      {availableLanguages?.map((l) => (
        <Menu.Item key={l.language}>{l.name}</Menu.Item>
      ))}
    </Menu>
  )

  return (
    <>
      <Radio.Group onChange={onChange} value={selectedLanguage?.language} className="mr-3">
        {selectedLanguages?.map((l) => (
          <Tooltip
            placement="top"
            key={l.language}
            title={(
              <Button
                type="link"
                style={{ height: '80%' }}
                icon={<DeleteFilled style={{ color: 'white' }} />}
                onClick={(e) => {
                  e.stopPropagation()
                  handleRemove(l.language)
                }}
              />
            )}
          >
            <Radio.Button
              key={l.language}
              value={l.language}
            >
              {l.name}
            </Radio.Button>
          </Tooltip>
        ))}
      </Radio.Group>
      <Dropdown overlay={menu}>
        <Button>
          Add translation
          <PlusOutlined />
        </Button>
      </Dropdown>
    </>
  )
}

export default HandleLanguage

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Card, Input, Row, Space, Table } from 'antd'
import { EditOutlined, LeftOutlined, SearchOutlined, UndoOutlined } from '@ant-design/icons'
import { getArchivedTransactions, updateTransaction } from '../../services/project/transactionService'
import { Project } from '../../types/project'
import HeaderWithAvatar from '../../components/HeaderAvatar'
import { Transaction } from '../../types/transaction'
import { formatDate } from '../../tools/dateTools'

const ArchivesPageTransaction = () => {
  const navigate = useNavigate()
  const [data, setData] = useState<Transaction[]>([])
  const [originalData, setOriginalData] = useState<Transaction[]>([])
  const [search, setSearch] = useState('')

  const loadTransactions = async () => {
    const transactions = await getArchivedTransactions()
    setOriginalData(transactions)
    setData(transactions)
  }

  useEffect(() => {
    loadTransactions()
  }, [])

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  useEffect(() => {
    const filteredData = originalData.filter((entry) => (
      entry.projectName?.toLowerCase().includes(search.toLowerCase())
    ))
    setData(filteredData)
  }, [search])

  const restoreTransaction = async (p: Transaction) => {
    await updateTransaction({ archived: false }, p.id)
    await loadTransactions()
  }

  const clickHandler = (t: Transaction) => {
    navigate(`/transactions/${t.id}`)
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Project Name',
      dataIndex: 'projectName',
      key: 'projectName',
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: any) => (
        <Space size="middle">
          {formatDate(text)}
        </Space>
      ),
    },
    {
      title: 'Updated at',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text: any) => (
        <Space size="middle">
          {formatDate(text)}
        </Space>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      render: (text: typeof data[number], record: typeof data[number]) => (
        <Space size="middle">
          <Button
            type="default"
            icon={<UndoOutlined />}
            onClick={(e) => {
              e.stopPropagation()
              restoreTransaction(record)
            }}
          />
          <Button
            type="default"
            icon={<EditOutlined />}
            onClick={() => clickHandler(record)}
          />
        </Space>
      ),
    },
  ]

  return (
    <>
      <HeaderWithAvatar>
        <div style={{ display: 'flex' }}>
          <Button
            icon={<LeftOutlined />}
            shape="circle"
            onClick={() => navigate('/projects')}
            className="mr-3"
            type="link"
          />
          <h3 className="pt-1">
            <b>
              Transactions /
            </b>
            {' '}
            Archives
          </h3>
        </div>
      </HeaderWithAvatar>
      <Card>
        <Row justify="end">
          <Space>
            <Input
              prefix={<SearchOutlined />}
              placeholder="Search"
              value={search}
              onChange={handleSearch}
            />
          </Space>
        </Row>

        <Table
          dataSource={data}
          columns={columns}
        />
      </Card>
    </>
  )
}

export default ArchivesPageTransaction

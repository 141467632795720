export const USER_ROLES = {
  ADMIN: 'admin',
  WEMBER: 'wember',
  MANAGER: 'manager',
  OPERATOR: 'operator',
}

export const WILDSENSE_ROLES = {
  ADMIN: 'admin',
  WEMBER: 'wember',
}

export const CUSTOMER_ROLES = {
  MANAGER: 'manager',
  OPERATOR: 'operator',
}

export const languages = [
  { value: 'en', label: 'English' },
  { value: 'fr', label: 'Français' },
]

export interface User {
  id: number
  firstname: string
  lastname: string
  name: string
  email: string
  status: string
  mailingList: string
  avatarUrl: string | undefined
  active: boolean
  language: string
  availability: JSON
  wemberId: number
  managerId: number
  phone: string
  stripeCustomerId: string
  contractUrl: string
  lastLogin: Date
  createdAt: Date
  updatedAt: Date
  companyId: number
  company: Company
  wember: User
  wemberRef: string
  operatorManagementPermissionId: number
}

export interface Company {
  id:number
  name:string
  website:string
  phone:string
  address:string
  city:string
  zipcode:string
  country:string
  createdAt:Date
  updatedAt:Date
}

/* eslint-disable react/require-default-props */
import React from 'react'
import { Table as AntdTable, Space, TableProps as AntdTableProps, Switch, Button, Image, List } from 'antd'

import { Key } from 'antd/lib/table/interface'
import { DeleteOutlined, EditOutlined, UndoOutlined } from '@ant-design/icons'
import { formatDate } from '../tools/dateTools'
import { getApiAssets } from '../services'

export interface TableProps extends AntdTableProps<any> {
  data: any,
  cols: string[],
  onRowsSelection ?: any,
  type ?: string,
  [x:string]: any,
}

export interface ColumnProps {
  title: string,
  dataIndex ?: string | string[],
  key: string,
  [x:string]: any,
}

const Table = ({ data, cols, onClick = undefined, onRowsSelection = undefined, type, ...rest }: TableProps) => {
  const projectColumns : ColumnProps[] = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      sorter: (a: typeof data[number], b: typeof data[number]) => a.id - b.id,
    },
    {
      title: 'Project Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: typeof data[number], b: typeof data[number]) => a.name?.localeCompare(b.name),
    },
    {
      title: 'Account Owner',
      dataIndex: ['owner', 'name'],
      key: 'owner',
      sorter: (a: typeof data[number], b: typeof data[number]) => a.owner?.name?.localeCompare(b.owner?.name),
    },
    {
      title: 'Last Modified',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text: any) => (
        <Space size="middle">
          {formatDate(text)}
        </Space>
      ),
      sorter:
      (
        a: typeof data[number],
        b: typeof data[number],
      ) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime(),
      defaultSortOrder: 'descend',
    },
    {
      title: 'By',
      dataIndex: ['lastEditedBy', 'name'],
      key: 'lastEditedBy',
      sorter: (
        a: typeof data[number],
        b: typeof data[number],
      ) => a.lastEditedBy?.name?.localeCompare(b.lastEditedBy?.name),
    },
    {
      title: 'Visible',
      dataIndex: 'visible',
      key: 'visible',
      sorter: (a: typeof data[number]) => (a.visible ? 1 : -1),
      render: (v: typeof data[number], r: typeof data[number]) => (
        <Space onClick={(e) => e.stopPropagation()} style={{ width: '100%', justifyContent: 'center' }}>
          <Switch
            size="small"
            checked={v}
            disabled={!r.submitted}
            onChange={() => {
              rest?.onToggleSwitch(r)
            }}
          />
        </Space>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'submitted',
      key: 'submitted',
      sorter: (a: typeof data[number]) => (a?.submitted ? 1 : -1),
      render: (v: typeof data[number]) => (v ? 'Submitted' : 'Drafted'),
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      render: (text: typeof data[number], record: typeof data[number]) => (
        <Space size="middle">
          <Button
            type="default"
            icon={<DeleteOutlined />}
            onClick={(e) => {
              e.stopPropagation()
              rest?.onArchive(record)
            }}
          />
          <Button
            type="default"
            icon={<EditOutlined />}
          />
        </Space>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actionArchives',
      key: 'actionArchives',
      render: (text: typeof data[number], record: typeof data[number]) => (
        <Space size="middle">
          <Button
            type="default"
            icon={<UndoOutlined />}
            onClick={(e) => {
              e.stopPropagation()
              rest?.onRestore(record)
            }}
          />
          <Button
            type="default"
            icon={<EditOutlined />}
          />
        </Space>
      ),
    },
  ]

  const listColumns : ColumnProps[] = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      sorter: (a: typeof data[number], b: typeof data[number]) => a.id - b.id,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: typeof data[number], b: typeof data[number]) => a.name?.localeCompare(b.name),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a: typeof data[number], b: typeof data[number]) => a.email?.localeCompare(b.email),
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      sorter: (a: typeof data[number], b: typeof data[number]) => a.phone?.localeCompare(b.phone),
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      sorter: (a: typeof data[number], b: typeof data[number]) => a.address?.localeCompare(b.address),
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      sorter: (a: typeof data[number], b: typeof data[number]) => a.city?.localeCompare(b.city),
    },
    {
      title: 'Zipcode',
      dataIndex: 'zipcode',
      key: 'zipcode',
      sorter: (a: typeof data[number], b: typeof data[number]) => a.zipcode?.localeCompare(b.zipcode),
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      sorter: (a: typeof data[number], b: typeof data[number]) => a.country?.localeCompare(b.country),
    },
    {
      title: 'Icon',
      dataIndex: 'iconUrl',
      key: 'iconUrl',
      sorter: (a: typeof data[number], b: typeof data[number]) => a.iconUrl?.localeCompare(b.iconUrl),
      render: (src: any) => (
        <Image preview={false} src={getApiAssets(src)} width={40} height={40} />
      ),
    },
    {
      title: 'Translations',
      dataIndex: 'translations',
      key: 'translations',
      render: (tab: any[]) => (
        tab && tab.length ? (
          <List
            itemLayout="horizontal"
            dataSource={tab}
            size="small"
            renderItem={(item) => (
              <List.Item>
                {`${item?.language} : ${item?.name}`}
              </List.Item>
            )}
          />
        ) : null
      ),
    },
    {
      title: 'Last Modified',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text: any) => (
        <Space size="middle">
          {formatDate(text)}
        </Space>
      ),
      sorter:
      (
        a: typeof data[number],
        b: typeof data[number],
      ) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime(),
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      render: (text: typeof data[number], record: typeof data[number]) => (
        <Space size="middle">
          <Button
            type="default"
            icon={<DeleteOutlined />}
            onClick={(e) => {
              e.stopPropagation()
              rest?.onArchive(record)
            }}
          />
          <Button
            type="default"
            icon={<EditOutlined />}
          />
        </Space>
      ),
    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys: Key[], selectedRows: typeof data) => {
      if (onRowsSelection) { onRowsSelection(selectedRowKeys, selectedRows) }
    },
  }

  const getColumns = () => {
    if (type === 'list') return listColumns.filter((col) => cols.includes(col.key))
    return projectColumns.filter((col) => cols.includes(col.key))
  }

  return (
    <div className="table-wrap">
      <AntdTable
        rowSelection={{ ...rowSelection }}
        onRow={(record: typeof data[number]) => ({
          onClick: () => {
            if (onClick) { onClick(record) }
          },
        })}
        size="middle"
        style={{ marginTop: 20 }}
        dataSource={data.map((d:any, v:number) => { d.key = v; return d })}
        columns={getColumns()}
        {...rest}
      />
    </div>
  )
}

export default Table

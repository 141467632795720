/* eslint-disable max-len */
export default {
  auth: {
    login: {
      login: 'Login',
      email: 'Email',
      password: 'Password',
      error: 'Invalid Email or Password',
    },
    register: {},
    recovery: {},
  },

  table: {
    name: 'Name',
    actions: 'Actions',
    status: 'Status',
    company: 'Company',
    email: 'Email',
    lastLogin: 'Last Login',
    admin: 'Administrator',
    wember: 'Wember',
    manager: 'Manager',
    operator: 'Operator',
  },

  upload: {
    missingIcon: 'The icon field is mandatory',
    isNotJpgOrPng: 'Please select a .png or .jpg file',
    isNotSvg: 'Please select a .svg file',
    wrongType: 'Wrong file type',
    tooBig: 'File is too big',
    uploadAvatar: 'Upload avatar',
    uploadIcon: 'Upload icon',
    upload: 'Upload',
  },

  settings: {
    create: 'Add a new Wember',
    edit: 'Edition of Wember ',
    firstname: 'Firstname',
    lastname: 'Lastname',
    email: 'Email',
    password: 'Password',
    phone: 'Phone',
    language: 'Language',
    createWember: 'Add Wember',
    editWember: 'Edit Wember',
    dayFrom: 'From (day)',
    dayTo: 'To (day)',
    hourFrom: 'From (hour)',
    hourTo: 'To (hour)',
    day0: 'Monday',
    day1: 'Tuesday',
    day2: 'Wednesday',
    day3: 'Thursday',
    day4: 'Friday',
    day5: 'Saturday',
    day6: 'Sunday',
    status: 'Status',
  },
}

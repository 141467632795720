export const TextTypes = {
  WHY_WE_LOVE: 'whyWeLove',
  CAREGIVERS: 'caregivers',
  TREE_SPECIES: 'treeSpecies',
  BIOLOGICAL_SPECIES: 'biologicalSpecies',
  BENEFIT: 'benefit',
  BIODIVERSITY: 'biodiversity',
  LIVELIHOOD: 'livelihood',
}

export interface Request {
  name: string
}

export interface MilestoneRequest extends Request {
  icon: File
}

export interface DeveloperRequest extends Request {
  email: string,
  phone: string,
  address: string,
  city: string,
  zipcode: string,
  country: string
}

export interface TextRequest {
  type: string,
  language: string,
  text: string
}

export interface ProjectMilestoneRequest {
  date: string,
}

export interface TranslationRequest {
  name: string,
  language: string
}

export interface ProjectRequest {
  name?: string
  projectDeveloperId?: number
  countryId?: number
  markerLocation?: string
  shapefile?: File | any
  imagesUrl?: string
  images?: any[]
  imageOrder?: string
  price?: number
  carbonCapacity?: number
  carbonAvailable?: number
  visible?: boolean
  archived?: boolean
  submitted?: boolean
  ownerId?: number
  projectTypeId?: number
  treeSpecies?: number[]
  biologicalSpecies?: number[]
  benefits?: number[]
  milestones?: number[]
}

export interface TransactionRequest {
  transactionId?: string,
  projectName?: string,
  carbonAllocated?: number,
  remainingCarbon?: number,
  issuedTo?: string,
  remainingOn?: Date,
  retiredBy?: string,
  retiredOn?: Date,
  archived?: boolean,
}

import React, { useState } from 'react'
import { Form, Input, Button, Row, Col } from 'antd'
import { useNavigate } from 'react-router-dom'
import { changeLanguage } from 'i18next'
import { useTranslation } from 'react-i18next'
import { setToken, setTokenExpiresAt, setUser } from '../../redux/reducers/userReducer'
import { useAppDispatch } from '../../redux/hooks'
import { authenticate } from '../../services/authService'
import { ReactComponent as WildsenseLogo } from '../../assets/wildsense_logo/logo_wildsense_green.svg'

const Login = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation('', { keyPrefix: 'auth.login' })
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const onFinish = async (values: any) => {
    const { email, password } = values

    try {
      if (!email || !password) {
        setErrorMessage(t('error'))
        return
      }
      setLoading(true)
      const data = await authenticate({ email, password })
      changeLanguage(data.language)
      setErrorMessage('')
      dispatch(setToken(data.token))
      dispatch(setTokenExpiresAt(data.expires_at))
      dispatch(setUser(data))
      navigate('/')
    } catch (err) {
      setErrorMessage(t('error'))
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo)
  }

  return (
    <Row style={{ width: '100%', height: '100vh' }} className="login">
      <Col span={12} className="login-background" />
      <Col
        flex="auto"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
        }}
        className="login-card"
      >
        <div style={{
          width: '80%',
          backgroundColor: 'white',
          padding: 40,
          borderRadius: 20,
        }}
        >
          <WildsenseLogo />
          <h1>Login</h1>
          <Form
            name="login"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item
              label={t('email')}
              name="email"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t('password')}
              name="password"
              rules={[{ required: true }]}
            >
              <Input.Password />
            </Form.Item>

            <p className="error">{errorMessage}</p>
            <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
              <Button type="primary" htmlType="submit" loading={loading}>
                {t('login')}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Col>
    </Row>
  )
}

export default Login

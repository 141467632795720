import React, { CSSProperties, useEffect, useState } from 'react'
import moment from 'moment'
import { Button, Space, Card, DatePicker, Image, Form, Radio, FormInstance, Row, Col } from 'antd'

import { HolderOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Milestone } from '../../../types/project'
import { getApiAssets } from '../../../services'
import { getMilestones } from '../../../services/project/milestoneService'
import { ProjectRequest } from '../../../types/service'
import { parseMomentToMilestoneDate } from '../../../tools/dateTools'
import ImageUploader from '../../../components/ImageUploader'
import { DragDropContext, Draggable, DraggingStyle, Droppable, DropResult, NotDraggingStyle } from 'react-beautiful-dnd'
import { ReactComponent as DragIcon } from '../../../assets/icons/drag.svg'
import FileUploader from '../../../components/FileUploader'

const grid = 2

// eslint-disable-next-line no-unused-vars
const getListStyle = (isDraggingOver: boolean): CSSProperties => ({
  display: 'block',
  padding: 0,
  overflow: 'auto',
  height: '100%',
})

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
): CSSProperties => ({
  userSelect: 'none',
  padding: 0,
  margin: 4,
  display: 'block',
  placeItems: 'center',
  background: 'transparent',
  ...draggableStyle,
})

const DateMilestone = ({ value = '' }: { value?: string }) => (<div>{value}</div>)

const ShowMilestone = ({ value = 0, milestones }: { value?: number, milestones: Milestone[] }) => {
  const found = milestones?.find((m) => m?.id === value)
  return (
    <Space direction="horizontal">
      <Image
        width={32}
        preview={false}
        src={getApiAssets(found?.iconUrl)}
      />
      <div className="ml-1">
        {found?.name}
      </div>
    </Space>
  )
}

const ProjectMilestones = ({ form }: { form: FormInstance<ProjectRequest | undefined> }) => {
  const [milestones, setMilestones] = useState<Milestone[]>([])

  const loadMilestones = async () => {
    setMilestones(await getMilestones())
  }

  useEffect(() => {
    loadMilestones()
  }, [])

  useEffect(() => {
    const date = form.getFieldValue('newMilestone')?.date
    if (typeof date === 'string') {
      form.setFieldsValue({ newMilestone: { date: moment(new Date(date)) } })
    }
  }, [form.getFieldValue('newMilestone')])

  const handleSave = (({ add }: { add: any }) => {
    const { milestoneId, date, files } = form.getFieldValue('newMilestone') || { milestoneId: null, date: null }
    if (!milestoneId || !date) return
    add({
      milestoneId,
      date: parseMomentToMilestoneDate(new Date(date)),
      files: files.map((file: any) => file.originFileObj),
    })
    form.resetFields(['newMilestone'])
  })

  const getFileList = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const reorder = (list: Milestone[], startIndex: number, endIndex: number): Milestone[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = ({ source, destination }: DropResult) => {
    if (!destination) {
      return
    }
    const found = form.getFieldValue('milestones')
    const newOrder = reorder(found, source.index, destination.index)
    form.setFieldsValue({ milestones: newOrder })
  }

  const Line = (props: { name: any, restField: any, remove: any, myKey: any }) => {
    const { name, restField, remove, myKey } = props
    return (
      <Space style={{ display: 'flex' }} align="baseline" key={myKey}>
        <DragIcon />
        <Form.Item
          {...restField}
          name={[name, 'milestoneId']}
        >
          <ShowMilestone milestones={milestones} />
        </Form.Item>
        <Form.Item
          {...restField}
          name={[name, 'date']}
        >
          <DateMilestone />
        </Form.Item>
        <MinusCircleOutlined onClick={() => remove(name)} />
      </Space>
    )
  }

  return (
    <>
      <Row>
        <Col flex="auto">
          <Form.Item name={['newMilestone', 'milestoneId']} label="1. Choose a milestone type">
            <Radio.Group
              optionType="button"
              size="large"
              buttonStyle="solid"
            >
              <Space direction="vertical">
                {milestones?.map(({ iconUrl, name, id }) => (
                  <Radio.Button key={name} value={id} className="milestone-button">
                    <Row>
                      <Col className="mr-3">
                        <Image preview={false} src={getApiAssets(iconUrl)} width={30} height={30} />
                      </Col>
                      <Col>
                        {name}
                      </Col>
                    </Row>
                  </Radio.Button>
                ))}
              </Space>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col flex="auto">
          <Form.Item name={['newMilestone', 'date']} label="2. Choose a date">
            {typeof form.getFieldValue('newMilestone')?.date !== 'string' && (
              <DatePicker
                picker="month"
                format="MM/YYYY"
              />
            )}
          </Form.Item>
        </Col>
        <Col flex="auto" className="mr-16">
          <Form.Item
            name={['newMilestone', 'files']}
            getValueFromEvent={getFileList}
            valuePropName="fileList"
            label="3. Upload a file"
          >
            <FileUploader />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Form.List name="milestones">
          {(fields, { add, remove }) => (
            <>
              <Col flex="auto">
                <Form.Item label="4. Add new milestone">
                  <Button icon={<PlusOutlined />} onClick={() => handleSave({ add })} type="primary">Add</Button>
                </Form.Item>
              </Col>
              <Col flex="auto" className="flex-grow-1" style={{ flexBasis: 0 }}>
                <div className="ant-form-item-label" style={{ fontSize: 17 }}>
                  Added milestones
                </div>
                <Card size="small" className="mb-6 mt-0">
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable" direction="vertical">
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                          {fields.map(({ key, name, ...restField }) => (
                            <Draggable key={key} draggableId={key.toString()} index={key}>
                              {(provided2, snapshot2) => (
                                <div
                                  ref={provided2.innerRef}
                                  {...provided2.draggableProps}
                                  {...provided2.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot2.isDragging,
                                    provided2.draggableProps.style
                                  )}
                                >
                                  <Line key={key} myKey={key} name={name} restField={restField} remove={remove} />
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Card>
              </Col>
            </>
          )}
        </Form.List>
      </Row>
    </>
  )
}

export default ProjectMilestones

import React from 'react'
import { GeoJSON } from 'react-leaflet'
import * as turf from '@turf/turf'
import { FeatureCollection } from '@turf/turf'

export function getParcelCenter(data: FeatureCollection) {
  const center = turf.center(data)
  return { lat: center.geometry.coordinates[1], lng: center.geometry.coordinates[0] }
}

export const renderParcel = (data: FeatureCollection) => {
  if (data) {
    return (
      <GeoJSON
        key={JSON.stringify(data)}
        data={data}
        pathOptions={{ color: 'white' }}
      />
    )
  }
  return null
}

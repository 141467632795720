/* eslint-disable @typescript-eslint/ban-ts-comment */
import { MilestoneRequest, ProjectMilestoneRequest, TranslationRequest } from '../../types/service'
import api, { apiFormData } from '../index'

export const getMilestones = async () => {
  try {
    const response = await api.get('/milestones/')
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const createMilestone = async (request: MilestoneRequest) => {
  try {
    const formData = new FormData()
    Object.keys(request).forEach((key) => {
    // @ts-ignore
      if (request[key] !== 'undefined' && request[key] !== null && request[key] !== undefined) {
        // @ts-ignore
        if (Array.isArray(request[key])) {
        // @ts-ignore
          request[key].forEach((item: File) => formData.append(`${key}[]`, item))
        } else {
        // @ts-ignore
          formData.append(key, request[key])
        }
      }
    })
    const response = await apiFormData.post('/milestones/', formData)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getMilestone = async (id: number) => {
  try {
    const response = await api.get(`/milestones/${id}`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const updateMilestone = async (request: MilestoneRequest, id: number) => {
  try {
    const formData = new FormData()
    Object.keys(request).forEach((key) => {
    // @ts-ignore
      if (request[key] !== 'undefined' && request[key] !== null && request[key] !== undefined) {
        // @ts-ignore
        if (Array.isArray(request[key])) {
        // @ts-ignore
          request[key].forEach((item: File) => formData.append(`${key}[]`, item))
        } else {
        // @ts-ignore
          formData.append(key, request[key])
        }
      }
    })
    const response = await apiFormData.patch(`/milestones/${id}`, formData)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getMilestoneTranslations = async (milestoneId: number) => {
  try {
    const response = await api.get(`/milestones/${milestoneId}/milestoneTranslations`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const createMilestoneTranslation = async (milestoneId: number, request: TranslationRequest) => {
  try {
    const response = await api.post(`/milestones/${milestoneId}/milestoneTranslations`, request)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getMilestoneTranslation = async (milestoneId: number, id: number) => {
  try {
    const response = await api.get(`/milestones/${milestoneId}/milestoneTranslations/${id}`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const updateMilestoneTranslation = async (milestoneId: number, id: number, request: TranslationRequest) => {
  try {
    const response = await api.patch(`/milestones/${milestoneId}/milestoneTranslations/${id}`, request)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const deleteMilestoneTranslation = async (milestoneId: number, id: number) => {
  try {
    const response = await api.delete(`/milestones/${milestoneId}/milestoneTranslations/${id}`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getProjectMilestones = async (projectId: number, milestoneId: number) => {
  try {
    const response = await api.get(`/projects/${projectId}/milestones/${milestoneId}`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const createProjectMilestone = async (
  projectId: number,
  milestoneId: number,
  request: ProjectMilestoneRequest,
) => {
  // try {
  //   const response = await api.post(`/projects/${projectId}/milestones/${milestoneId}`, request)
  //   return response.data
  // } catch (e) {
  //   console.error(e)
  //   throw e
  // }
  const formData = new FormData()
  Object.keys(request).forEach((key) => {
    // @ts-ignore
    if (request[key] !== 'undefined' && request[key] !== null && request[key] !== undefined) {
    // @ts-ignore
      if (Array.isArray(request[key])) {
        // @ts-ignore
        request[key].forEach((item: File) => formData.append(`${key}[]`, item))
      } else {
        // @ts-ignore
        formData.append(key, request[key])
      }
    }
  })
  const response = await apiFormData.post(`/projects/${projectId}/milestones/${milestoneId}`, formData)
  return response.data
}

export const getProjectMilestone = async (projectId: number, milestoneId: number, id: number) => {
  try {
    const response = await api.get(`/projects/${projectId}/milestones/${milestoneId}/${id}`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const updateProjectMilestone = async (
  projectId: number,
  milestoneId: number,
  id: number,
  request: ProjectMilestoneRequest,
) => {
  // try {
  //   const response = await api.patch(`/projects/${projectId}/milestones/${milestoneId}/${id}`, request)
  //   return response.data
  // } catch (e) {
  //   console.error(e)
  //   throw e
  // }
  const formData = new FormData()
  Object.keys(request).forEach((key) => {
    // @ts-ignore
    if (request[key] !== 'undefined' && request[key] !== null && request[key] !== undefined) {
    // @ts-ignore
      if (Array.isArray(request[key])) {
        // @ts-ignore
        request[key].forEach((item: File) => formData.append(`${key}[]`, item))
      } else {
        // @ts-ignore
        formData.append(key, request[key])
      }
    }
  })
  const response = await apiFormData.patch(`/projects/${projectId}/milestones/${milestoneId}/${id}`, formData)
  return response.data
}

export const deleteProjectMilestone = async (projectId: number, milestoneId: number, id: number) => {
  try {
    const response = await api.delete(`/projects/${projectId}/milestones/${milestoneId}/${id}`)
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}
